<template>


<!-- Header Area -->

 <NavbarHome2/>

<!-- Hero Area -->

<div id="home-2" class="homepage-slides">
	<swiper
			:modules="modules"
			:slides-per-view="1"
			:space-between="0"
			navigation
			:pagination="{ clickable: true }"
			:autoplay="{ delay: 4000 }"
	>
	
		<swiper-slide>
			<div class="single-slide-item hero-area-bg-3">
            <div class="image-layer">
                <div class="overlay"></div>
            </div>
            <div class="hero-area-content">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7 wow fadeInUp animated" data-wow-delay=".2s">
                            <div class="section-title">
                                <h1>
                                    Solar Panel <br>
                                    Save Ecology
                                </h1>
                            </div>
                            <p>
                                Global warming solar revolution Lorem ipsum dolor sit, amet
                                consectetur adipisicing elit. Consequatur odit iure incidunt
                                minus recusandae minima laboriosam adipisci.
                            </p>
                            <router-link :to="{name:'About'}" class="main-btn primary">Learn More</router-link>
                        </div>
                        <div class="col-lg-5">
                            <div class="pop-up-video">
                                <a href="https://www.youtube.com/watch?v=yFwGpiCs3ss" class="video-play-btn mfp-iframe">
                                    <i class="las la-play"></i> <span>Play Video</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
		</swiper-slide>
	

		<swiper-slide>
			<div class="single-slide-item hero-area-bg-4">
            <div class="image-layer" style="background-image: url(assets/img/slider/slide-2.jpg)">
                <div class="overlay"></div>
            </div>
            <div class="hero-area-content">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7 wow fadeInUp animated" data-wow-delay=".2s">
                            <div class="section-title">
                                <h1>
                                    Modern Vision <br>
                                    in Solar Energy
                                </h1>
                            </div>
                            <p>
                                Global warming solar revolution Lorem ipsum dolor sit, amet
                                consectetur adipisicing elit. Consequatur odit iure incidunt
                                minus recusandae minima laboriosam adipisci.
                            </p>
                            <router-link :to="{name:'About'}" class="main-btn primary">Learn More</router-link>
                        </div>
                        <div class="col-lg-5">
                            <div class="pop-up-video">
                                <a href="https://www.youtube.com/watch?v=yFwGpiCs3ss" class="video-play-btn mfp-iframe">
                                    <i class="las la-play"></i> <span>Play Video</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
		</swiper-slide>
</swiper>

</div>

    <!-- Feature Area  -->

    <div class="feature-area">
        <div class="container">
            <div class="feature-wrap">
                <div class="row gx-0">
                    <div class="col-lg-4 col-md-6 col-12">
                        <div class="single-feature-item">
                            <div class="row gx-0">
                                <div class="col-lg-4 col-md-12 col-12">
                                    <div class="feat-icon">
                                        <img src="assets/img/feature/1.png" alt="">
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-12 col-12">
                                    <div class="feat-content">
                                        <h5>Global Expertise</h5>
                                        <p>
                                            It is a long established fact that solar energy will be
                                            lead.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-12">
                        <div class="single-feature-item">
                            <div class="row gx-0">
                                <div class="col-lg-4 col-md-12 col-12">
                                    <div class="feat-icon">
                                        <img src="assets/img/feature/2.png" alt="">
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-12 col-12">
                                    <div class="feat-content">
                                        <h5>Green Energy</h5>
                                        <p>
                                            It is a long established fact that solar energy will be
                                            lead.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-12">
                        <div class="single-feature-item">
                            <div class="row gx-0">
                                <div class="col-lg-4 col-md-12 col-12">
                                    <div class="feat-icon">
                                        <img src="assets/img/feature/3.png" alt="">
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-12 col-12">
                                    <div class="feat-content">
                                        <h5>Home Solutions</h5>
                                        <p>
                                            It is a long established fact that solar energy will be
                                            lead.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Company History -->

    <div class="history-section gray-bg section-padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-7 wow fadeInDown" data-wow-delay=".3s">
                    <div class="section-title">
                        <h6>Company History</h6>
                        <h2>
                            Professional Successful Renewable Energy Storage Solutions
                        </h2>
                    </div>
                </div>
                <div class="col-lg-5 wow fadeInUp" data-wow-delay=".3s">
                    <div class="history-desc">
                        <div class="histroy-desc-inner">
                            <p>
                                In startup in 1995 we are pioneer Lorem ipsum dolor, sit amet
                                consectetur adipisicing elit. Aliquid veniam quibusdam
                                voluptate odit voluptatibus voluptatem pariatur ad mollitia
                                exercitationem.
                            </p>
                            <a href="about.html">Read More <i class="las la-long-arrow-alt-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Portfolio Section -->

    <div class="portfolio-area-2">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="portfolio-list recent">
                        <div :class="'single-portfolio-item '+project.categories" v-for="project in projects.slice(0,3)" :key="project.id" :style="'background-image:url('+project.background_image+')'">
						<div class="details" :class="'project-'+project.id">
							<div class="info">
								<h5><router-link :to="{name:'ProjectDetails'}">{{project.title}}</router-link></h5>
								<router-link :to="{name:'ProjectDetails'}"><i class="las la-arrow-right"></i></router-link>
							</div>
						</div>
					</div>	
                        
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Service Section  -->

    <div id="service-2" class="service-area section-padding">
        <div class="container">
            <div class="row justify-content-center text-center">
                <div class="col-lg-9 wow fadeInUp" data-wow-delay=".3s">
                    <div class="section-title">
                        <h6>Our Service</h6>
                        <h2>We are provide world class Services & Solutions</h2>
                        <p>
                            There are some Lorem, ipsum dolor sit amet consectetur
                            adipisicing elit. Minus minima incidunt est inventore ut
                            laboriosam autem nisi nobis corrupti reprehenderit.
                        </p>
                    </div>
                </div>
            </div>
            <div class="service-item-wrap mt-40">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3 wow fadeInLeft" data-wow-delay=".2s" v-for="service in services.slice(0,4)" :key="service.id">
						<div class="service-single" :class="'service-'+service.id">
							<div class="service-icon">								
								<img :src="service.icon">
							</div>
							<div class="service-content">
								<h4>{{service.title}}</h4>
								<p>{{service.short_description}}</p>
								<a href="renew_energy.html">Learn More <i class="las la-long-arrow-alt-right"></i></a>
							</div>
						</div>
					</div>	
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Choose Us Section  -->
    <div class="choose-us-section section-padding pt-0">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 wow fadeInLeft" data-wow-delay=".3s">
                    <div class="chooseus-image-wrap">
                        <div class="single-img">
                            <img src="assets/img/chooseus/1.jpg" alt="">
                        </div>
                        <div class="double-img">
                            <img src="assets/img/chooseus/2.jpg" alt="">
                            <img src="assets/img/chooseus/3.jpg" alt="">
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 offset-lg-1 wow fadeInUp" data-wow-delay=".3s">
                    <div class="chooseus-content">
                        <div class="section-title">
                            <h6>Why Choose Us</h6>
                            <h2>We are expert in Solar System</h2>
                        </div>
                        <p>
                            We gathered expeience Lorem ipsum dolor sit amet consectetur
                            adipisicing elit. Doloremque ipsam autem, dolores consequatur
                            eaque officiis?
                        </p>
                        <div class="feature-inner" v-for="choose_us in chooses_us" :key="choose_us.id">
                            <div class="feature-icon">
                                <i class="las la-check-circle"></i>
                            </div>
                            <div class="feature-content" :class="'choose_us-'+choose_us.id">
                                <h6>{{ choose_us.title }}</h6>
                                <p>{{ choose_us.short_description }}</p>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Expertise section -->

    <div class="expertise-section dark-bg">
        <div class="row">
            <div class="col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                <div class="expertise-content">
                    <div class="section-title">
                        <h6 class="text-white">Our Expertise</h6>
                        <h2 class="text-white">We are building a Livable Future</h2>
                    </div>
                    <p class="text-white">
                        Our expertise officer Lorem ipsum dolor sit amet consectetur,
                        adipisicing elit. Omnis tenetur impedit vitae temporibus, nesciunt
                        perferendis?
                    </p>
                    <div class="progress-bar-area">
                        <div class="single-bar-item">
                            <h6 class="text-white">Maintenance</h6>
                            <div id="bar1" class="barfiller">
                                <span class="tip">75%</span>
                                <span class="fill" data-percentage="75"></span>
                            </div>
                        </div>

                        <div class="single-bar-item">
                            <h6 class="text-white">Consultant</h6>
                            <div id="bar2" class="barfiller">
                                <span class="tip">90%</span>
                                <span class="fill" data-percentage="90"></span>
                            </div>
                        </div>

                        <div class="single-bar-item">
                            <h6 class="text-white">Manufactures</h6>
                            <div id="bar3" class="barfiller">
                                <span class="tip">60%</span>
                                <span class="fill" data-percentage="60"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="expertise-img"></div>
                <div class="subscribed-wrap">
                    <div class="subscribe-form">
                        <h3>Join the community of over a million people</h3>
                        <form action="index.html">
                            <input type="email" placeholder="Your email">
                            <button type="submit"><i class="las la-envelope"></i></button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Team Area-->

    <div class="team-area gray-bg section-padding">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12 ">
                    <div class="section-title text-center">
                        <h6>Our Team</h6>
                        <h2>We are a team of <b>creatives</b> <br>people <b>innovation</b></h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 col-md-6 wow fadeInLeft" data-wow-delay=".2s" v-for="team in teams.slice(0,4)" :key="team.id">
                    <div class="single-team-member" :class="'team-'+team.id">
                        <div class="team-member-bg" :style="'background-image:url('+team.background_image+')'">
                            <div class="team-content">
                                <div class="team-title">
                                    <a href="#">{{team.name}}</a>
                                </div>
                                <div class="team-subtitle">
                                    <p>{{team.designation}}</p>
                                </div>
                                <ul class="team-social">
                                    <li>
                                        <a href="#"><i class="la la-facebook" aria-hidden="true"></i> </a>
                                    </li>
                                    <li>
                                        <a href="#"><i class="la la-twitter" aria-hidden="true"></i> </a>
                                    </li>
                                    <li>
                                        <a href="#"><i class="la la-instagram" aria-hidden="true"></i> </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    </div>

    <!-- Counter Section  -->
    <div class="counter-section section-padding dark-bg">
        <div class="container">
            <div class="row">
                <div class="col-lg-9">
                    <div class="row counter-wrap-2">
                        <div class="col-lg-4">
                            <div class="counter-number">
                                <p class="counting">16</p>
                                <h6>Presence</h6>
                                <p>Countries accross five <br> continents</p>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="counter-number">
                                <p class="counting">43</p>
                                <h6>Gr. Total</h6>
                                <p>Installed Solar Panels <br> successfully</p>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="counter-number">
                                <p class="counting">102</p>
                                <h6>Projects</h6>
                                <p>Solar Solutions & Power <br> Energy Operative</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 d-flex align-items-center justify-content-center">
                    <router-link :to="{name:'Price'}" class="main-btn">Get a Quote</router-link>
                </div>
            </div>

        </div>
    </div>

    <!-- Client Section  -->

    <div class="client-area pt-40 pb-40">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="client-wrap">
                        
                        <div class="single-client">
                            <a href="#"><img src="assets/img/client/1.png" alt=""></a>
                        </div>
                        <div class="single-client">
                            <a href="#"><img src="assets/img/client/2.png" alt=""></a>
                        </div>
                        <div class="single-client">
                            <a href="#"><img src="assets/img/client/3.png" alt=""></a>
                        </div>
                        <div class="single-client">
                            <a href="#"><img src="assets/img/client/4.png" alt=""></a>
                        </div>
                        <div class="single-client">
                            <a href="#"><img src="assets/img/client/5.png" alt=""></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Blog Section -->

    <div class="blog-area gray-bg section-padding">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-lg-12 wow fadeInUp" data-wow-delay=".2s">
				<div class="section-title text-center">
					<h6>News & Articles</h6>
					<h2>Whats going on <b>in our blogs?</b></h2>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-4 col-md-6 col-12 mb-100" v-for="blog in blogs.slice(0,3)" :key="blog.id">
				<div class="single-blog wow fadeInLeft" data-wow-delay=".3s">
					<router-link class="blog-img" :to="{name:'BlogDetails', params:{ id: blog.id }}">
						<img :src="blog.images.blog_image" :alt="blog.title">
					</router-link>                                                        					
					<div class="blog-content"> <span><a href="#">{{blog.tag}}</a></span>
						<h3><router-link :to="{name:'BlogDetails', params:{ id: blog.id }}">{{ blog.title }}</router-link></h3>
						<div class="blog-date">
							<p>{{ blog.date }}</p>
						</div>
						<router-link :to="{name:'BlogDetails', params:{ id: blog.id }}" class="read-more">Read More</router-link>
					</div>
				</div>
			</div>			
		</div>
	</div>
</div>

</template>

<script>

import axios from 'axios'
import NavbarHome2 from '@/components/NavbarHome2.vue'

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination, A11y, Autoplay } from "swiper";


// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";


export default {
    name: 'HomeOne',
    components : {NavbarHome2, Swiper, SwiperSlide},
	data(){
        return {
            modules: [Navigation, Pagination, A11y, Autoplay],
            swiperOptions: {
                breakpoints: {
                354: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                575: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                767: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                },

                990: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                1200: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                },
                },
            },	
			services: [],						
			projects: [],						
			chooses_us: [],						
            blogs:[],
			teams: [],						
        }
    },

	async mounted(){  

    //scrolling to top of the window
    window.scrollTo(0, 0); 
        
	//Magnific Pop-up

	$(".video-play-btn").magnificPopup({
		type: "iframe",
	});

    //Progress Bar
		
		$("#bar1").barfiller({
		barColor: "#007CFF",
		duration: 5000,
		});

		$("#bar2").barfiller({
		barColor: "#007CFF",
		duration: 5000,
		});

		$("#bar3").barfiller({
		barColor: "#007CFF",
		duration: 5000,
		});

		let fetcheServicesdData = await axios.get("/assets/data/services.json");
        this.services = fetcheServicesdData.data.services;

		let fetcheFeatureData = await axios.get("/assets/data/features.json");
        this.features = fetcheFeatureData.data.features;

		let fetcheProjectData = await axios.get("/assets/data/projects.json");
        this.projects = fetcheProjectData.data.projects;

		let fetcheBlogsData = await axios.get("/assets/data/blogs.json");
        this.blogs = fetcheBlogsData.data.blogs;

		let fetcheChoosesUsData = await axios.get("/assets/data/chooses_us.json");
        this.chooses_us = fetcheChoosesUsData.data.chooses_us;

		let fetcheTeamData = await axios.get("/assets/data/team.json");
        this.teams = fetcheTeamData.data.teams;

	}
   
}
</script>