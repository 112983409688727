<template>

    <!-- Header Top  -->

    <HeaderTopbar/>

    <!-- Header Area -->

    <NavbarHome1/>

    <!-- Search Dropdown Area -->

    <Search/>

    <!-- Breadcrumb Area -->

    <Breadcrumb 
        mainTitle="Process" 
        breadContent="We provide products Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse provident explicabo atque numquam enim debitis earum minus, perferendis sed ipsum." 
        subTitle="Process"
    />

    <!--Process Section -->

    <div class="process-area section-padding">
	<div class="container">
		<div class="row">
			<div class="offset-lg-2 col-lg-8 text-center wow fadeInUp" data-wow-delay=".2s">
				<div class="section-title">
					<h6>Working Process</h6>
					<h2>How We Works with Solar</h2>
				</div>
			</div>
		</div>
		<div class="row process-item-wrap">
			<div class="col-lg-4 col-md-6 col-12 wow fadeInLeft" data-wow-delay=".3s" v-for="process in processes" :key="process.id">
				<div class="single-process-item" :class="'process-'+process.id">
					<div class="process-icon">
						<img :src="process.icon" alt="">
					</div>
					<div class="process-content">
						<h5>{{process.title}}</h5>
						<p>{{process.desc}}</p>
					</div>
				</div>
			</div>			
		</div>
	</div>
    </div>

    <!-- Feature Section  -->
    <div class="feature-section section-padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 wow fadeInLeft" data-wow-delay=".2s">
                    <div class="section-title">
                        <h6>Core Feature</h6>   
                        <h2>Save the Planet by <br> using Green Energy</h2>
                    </div>
                    <p>Soltrin provide Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam qui incidunt a sapiente quibusdam commodi!</p>
                    <div class="feature-wrap">
                        
                            <div class="feature-inner" v-for="feature in features" :key="feature.id">
                            <div class="feature-icon">
                                <i class="las la-check-circle"></i>
                            </div>
                            <div class="feature-content" :class="'feature-'+feature.id">
                                <h6>{{feature.title}}</h6>
                                <p>{{feature.short_description}}</p>
                            </div>
                        
                        </div>					
                    </div>
                </div>
                <div class="col-lg-3 wow fadeInUp" data-wow-delay=".2s">
                    <div class="feature-img">
                        <img src="assets/img/story_img.jpg" alt="">
                        <br><br>
                        <img src="assets/img/story_img_2.jpg" alt="">
                    </div>
                </div>
                <div class="col-lg-3 wow fadeInRight" data-wow-delay=".2s">
                    <div class="counter-wrap">
                        <div class="counter-number">
                            <p class="counting">16</p>
                            <h6>Presence</h6>
                            <p>Countries accross five continents</p>
                        </div>
                        <div class="counter-number">
                            <p class="counting">43</p>
                            <h6>Gr. Total</h6>
                            <p>Installed Solar Panels successfully</p>
                        </div>
                        <div class="counter-number">
                            <p class="counting">102</p>
                            <h6>Projects</h6>
                            <p>Solar Solutions & Power Energy Operative</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Testimonial Section  -->
    <TestimonialOne/>

    <!-- CTA Area  -->

    <div class="cta-area">
        <div class="container">
            <div class="row cta-content">
                <div class="col-lg-7 col-md-7 col-12 wow fadeInUp" data-wow-delay=".2s">
                    <h2>Let's Started Your Project </h2>
                    <p>Make an appointment get quote Lorem, ipsum dolor.</p>
                </div>
                <div class="col-lg-5 col-md-5 col-12 text-center wow fadeInUp" data-wow-delay=".3s">
                    <router-link :to="{name:'Price'}" class="main-btn">Get a Quote</router-link>
                </div>
            </div>
        </div>
    </div>

    <!-- Client Section  -->

    <div class="client-area pt-40 pb-40">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="client-wrap owl-carousel">
                        <div class="single-client">
                            <a href="#"><img src="assets/img/client/1.png" alt="" ></a>
                        </div>
                        <div class="single-client">
                            <a href="#"><img src="assets/img/client/2.png" alt="" ></a>
                        </div>
                        <div class="single-client">
                            <a href="#"><img src="assets/img/client/3.png" alt="" ></a>
                        </div>
                        <div class="single-client">
                            <a href="#"><img src="assets/img/client/4.png" alt="" ></a>
                        </div>
                        <div class="single-client">
                            <a href="#"><img src="assets/img/client/5.png" alt="" ></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>

<script>

import axios from 'axios'

import HeaderTopbar from '@/components/HeaderTopbar.vue'
import NavbarHome1 from '@/components/NavbarHome1.vue'
import Search from '@/components/Search.vue'
import TestimonialOne from '@/components/TestimonialOne.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'



export default {
    name: 'Process',
    components : {
        HeaderTopbar, 
        NavbarHome1,    
        Search,     
        TestimonialOne,
        Breadcrumb
        },
	data(){
        return {
            	
			services : [], 		
			features : [], 		
			projects : [], 		
			processes : [], 		
			blogs : []
			
        }
    },

	async mounted(){    

        //scrolling to top of the window
        window.scrollTo(0, 0); 
		
		let fetcheServicesdData = await axios.get("/assets/data/services.json");
        this.services = fetcheServicesdData.data.services;

		let fetcheFeatureData = await axios.get("/assets/data/features.json");
        this.features = fetcheFeatureData.data.features;

		let fetcheProcessData = await axios.get("/assets/data/processes.json");
        this.processes = fetcheProcessData.data.processes;


	}
   
}
</script>