<template>
<div class="testimonial-section dark-bg section-padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-5 wow fadeInDown" data-wow-delay=".2s">
                    <div class="section-title">
                        <h6 class="text-white">Happy Customers</h6>
                        <h2 class="text-white">Testimonial</h2>
                        <p class="text-white">Our customers Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla quod mollitia maxime in nam laborum eos, incidunt nostrum accusamus quae!</p>
                        <p class="text-white">Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia obcaecati natus vitae, modi eaque illum laudantium, voluptatibus est veritatis accusantium.</p>
                    </div>
                    
                    
                </div>
                <div class="col-lg-6 offset-lg-1 wow fadeInUp" data-wow-delay=".2s">
                    <div class="testimonial-wrap">
                        <swiper
                                :modules="modules"
                                :slides-per-view="1"
                                :space-between="30"
                                
                                :autoplay="{ delay: 4000 }"
                                :breakpoints="{
                                '575': {
                                    slidesPerView: 1,
                                    spaceBetween: 20,
                                },
                                '768': {
                                    slidesPerView: 1,
                                    spaceBetween: 40,
                                },
                                '990': {
                                    slidesPerView: 1,
                                    spaceBetween: 40,
                                },
                                '1024': {
                                    slidesPerView: 1,
                                    spaceBetween: 50,
                                },
                                }"
                            >
                        <swiper-slide v-for="testimonial in testimonials" :key="testimonial.id">
                            <div class="testimonial-inner">
                            <div class="testimonial-thumb">
                                <div class="testimonial-img">
                                    <img :src="testimonial.image" :alt="testimonial.name">
                                </div>
                                <div class="testimonial-meta">
                                    <h6>{{testimonial.name}}</h6>
                                    <p>{{testimonial.company}}</p>
                                </div>
                                <div class="testimonial-quote">
                                    <i class="las la-quote-right"></i>
                                </div>
                            </div>
                            <div class="testimonial-content">
                                <p>{{testimonial.description}}</p>
                            </div>
                        </div>
                        </swiper-slide>
                    </swiper>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination, A11y, Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

import axios from 'axios'
export default {
    name: 'TestimonialOne',
    components : {Swiper, SwiperSlide,},
    data(){
        return {
            modules: [Navigation, Pagination, A11y, Autoplay],            
            testimonials : []
        }
    },
    async mounted(){
       
        let fetchedData = await axios.get("/assets/data/testimonial_one.json");
        this.testimonials = fetchedData.data.testimonials;

        
    }
}
</script>