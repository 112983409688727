<template>

    <!-- Header Top  -->

    <HeaderTopbar/>

    <!-- Header Area -->

    <NavbarHome1/>

    <!-- Search Dropdown Area -->

    <Search/>

    <!-- Breadcrumb Area -->

    <Breadcrumb 
        mainTitle="About Us" 
        breadContent="We provide products Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse provident explicabo atque numquam enim debitis earum minus, perferendis sed ipsum." 
        subTitle="About Us"
    />

    <!-- About Section  -->

    <div class="about-area section-padding">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-6 wow fadeInLeft" data-wow-delay=".3s">
                    <div class="about-content-wrap">
                        <div class="section-title">
                            <h6>About Us</h6>
                            <h2>Power Your life <br> with Sunshine</h2>
                        </div>
                        <div class="about-content">
                            <div class="row">
                                <div class="col-12 col-lg-12">
                                    <div class="about-content-left">
                                        <p class="highlight">We drive Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil beatae voluptatem nemo? Repudiandae, aut debitis.</p>
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="about-content-inner">
                                                    <h5>Mission</h5>
                                                    <p>We want to reduce carbon footprint and environmental impact and develop other energy business globally.</p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="about-content-inner">
                                                    <h5>Vission</h5>
                                                    <p>We are a diversified clean energy company with emphasis on power generation and energy services and wind solutions.</p>
                                                </div>
                                            </div>
                                        </div>                                                                                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6 wow fadeInRight" data-wow-delay=".3s">
                    <div class="about-img text-end">
                        <img src="assets/img/about/about.png" alt="">
                        <div class="about-counter">                            
                            <div class="founder-number">
                                <p>10+</p>
                            </div>
                            <p>Year of Experience</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <!-- Company History -->

    <div class="history-section dark-bg section-padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-7">
                    <div class="section-title">
                        <h6 class="text-white">Company History</h6>
                        <h2 class="text-white">
                            Professional Successful Renewable Energy Storage Solutions
                        </h2>
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="history-desc">
                        <div class="histroy-desc-inner">
                            <p>
                                In startup in 1995 we are pioneer Lorem ipsum dolor, sit amet
                                consectetur adipisicing elit. Aliquid veniam quibusdam
                                voluptate odit voluptatibus voluptatem pariatur ad mollitia
                                exercitationem.
                            </p>
                            <a href="about.html">Read More <i class="las la-long-arrow-alt-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--Process Section -->

    <div class="process-area section-padding">
        <div class="container">
            <div class="row">
                <div class="offset-lg-2 col-lg-8 text-center wow fadeInUp" data-wow-delay=".2s">
                    <div class="section-title">
                        <h6>Working Process</h6>
                        <h2>How We Works with Solar</h2>
                    </div>
                </div>
            </div>
            <div class="row process-item-wrap">
                <div class="col-lg-4 col-md-6 col-12 wow fadeInLeft" data-wow-delay=".3s" v-for="process in processes" :key="process.id">
                    <div class="single-process-item" :class="'process-'+process.id">
                        <div class="process-icon">
                            <img :src="process.icon" alt="">
                        </div>
                        <div class="process-content">
                            <h5>{{process.title}}</h5>
                            <p>{{process.desc}}</p>
                        </div>
                    </div>
                </div>			
            </div>
        </div>
    </div>

    <!-- Fun Fact Section  -->
    <div class="funfact-section section-padding dark-bg">
        <div class="container">        
            <div class="row">
                <div class="col-lg-9">
                    <div class="row counter-wrap-2">
                        <div class="col-lg-4">
                            <div class="counter-number">
                                <p class="counting">16</p>                            
                                <h6>Presence</h6>
                                <p>Countries accross five <br> continents</p>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="counter-number">
                                <p class="counting">43</p>
                                <h6>Gr. Total</h6>
                                <p>Installed Solar Panels <br> successfully</p>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="counter-number">
                                <p class="counting">102</p>
                                <h6>Projects</h6>
                                <p>Solar Solutions & Power <br> Energy Operative</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 d-flex align-items-center justify-content-center">
                    <router-link :to="{name:'Price'}" class="main-btn">Get a Quote</router-link>
                </div>
            </div>
        
        </div>
    </div>

    <!-- Team Area-->

    <div class="team-area gray-bg section-padding">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12 ">
                    <div class="section-title text-center">
                        <h6>Our Team</h6>
                        <h2>We are a team of <b>creatives</b> <br>people <b>innovation</b></h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 col-md-6 wow fadeInLeft" data-wow-delay=".2s" v-for="team in teams" :key="team.id">
                    <div class="single-team-member" :class="'team-'+team.id">
                        <div class="team-member-bg" :style="'background-image:url('+team.background_image+')'">
                            <div class="team-content">
                                <div class="team-title">
                                    <a href="#">{{team.name}}</a>
                                </div>
                                <div class="team-subtitle">
                                    <p>{{team.designation}}</p>
                                </div>
                                <ul class="team-social">
                                    <li>
                                        <a href="#"><i class="la la-facebook" aria-hidden="true"></i> </a>
                                    </li>
                                    <li>
                                        <a href="#"><i class="la la-twitter" aria-hidden="true"></i> </a>
                                    </li>
                                    <li>
                                        <a href="#"><i class="la la-instagram" aria-hidden="true"></i> </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    </div>


</template>

<script>

import axios from 'axios'

import HeaderTopbar from '@/components/HeaderTopbar.vue'
import NavbarHome1 from '@/components/NavbarHome1.vue'
import Search from '@/components/Search.vue'
import TestimonialOne from '@/components/TestimonialOne.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'


export default {
    name: 'About',
    components : {HeaderTopbar, NavbarHome1, Search, TestimonialOne, Breadcrumb},
	data(){
        return {            	
			services : [], 		
			features : [], 		
			teams : [], 		
			processes : [], 		
			blogs : []
			
        }
    },

	async mounted(){    

        //scrolling to top of the window
        window.scrollTo(0, 0);          
		
		let fetcheServicesdData = await axios.get("/assets/data/services.json");
        this.services = fetcheServicesdData.data.services;

		let fetcheFeatureData = await axios.get("/assets/data/features.json");
        this.features = fetcheFeatureData.data.features;

		let fetcheProjectData = await axios.get("/assets/data/projects.json");
        this.projects = fetcheProjectData.data.projects;

		let fetcheProcessData = await axios.get("/assets/data/processes.json");
        this.processes = fetcheProcessData.data.processes;

        let fetcheTeamData = await axios.get("/assets/data/team.json");
        this.teams = fetcheTeamData.data.teams;

		let fetcheBlogsData = await axios.get("/assets/data/blogs.json");
        this.blogs = fetcheBlogsData.data.blogs;



	}
   
}
</script>