<template>

<!-- Header Top  -->

 <HeaderTopbar/>

<!-- Header Area -->

 <NavbarHome1/>

<!-- Search Dropdown Area -->

<Search/>

<!-- Hero Area -->

<div class="homepage-slides">
	<swiper
			:modules="modules"
			:slides-per-view="1"
			:space-between="0"
			navigation
			:pagination="{ clickable: true }"
			:autoplay="{ delay: 4000 }"
	>
	
		<swiper-slide>
			<div class="single-slide-item">
		<div class="image-layer">
			<div class="overlay"></div>
		</div>
		<div class="hero-area-content">
			<div class="container">
				<div class="row">
					<div class="col-lg-8 wow fadeInUp animated" data-wow-delay=".2s">
						<div class="section-title">
							<h1>Solar Revolution<br> Renewable Energy</h1>
						</div>
						<p>Global warming solar revolution Lorem ipsum dolor sit, amet consectetur adipisicing elit. <br> Consequatur odit iure incidunt minus recusandae minima laboriosam adipisci.</p>						
						<router-link :to="{name:'About'}" class="main-btn primary">Learn More</router-link>
					</div>
				</div>
			</div>
		</div>
		</div>
		</swiper-slide>
	

		<swiper-slide>
			<div class="single-slide-item hero-area-bg-2">
		<div class="image-layer" style="background-image: url(assets/img/slider/slide-2.jpg);">
			<div class="overlay"></div>
		</div>
		<div class="hero-area-content">
			<div class="container">
				<div class="row">
					<div class="col-lg-8 wow fadeInUp animated" data-wow-delay=".2s">
						<div class="section-title">
							<h1>Wind Turbine <br> Storage Sololution</h1>
						</div>
						<p>Global warming solar revolution Lorem ipsum dolor sit, amet consectetur adipisicing elit. <br> Consequatur odit iure incidunt minus recusandae minima laboriosam adipisci.</p>
						<router-link :to="{name:'About'}" class="main-btn primary">Learn More</router-link>
					</div>
				</div>
			</div>
		</div>
		</div>
		</swiper-slide>
</swiper>

</div>

<!-- About Section  -->

<div id="about-1" class="about-area section-padding">
	<div class="container">
		<div class="row">
			<div class="col-12 col-lg-6 wow fadeInLeft" data-wow-delay=".3s">
				<div class="about-content-wrap">
					<div class="section-title">
						<h6>About Us</h6>
						<h2>Power Your life <br> with Sunshine</h2>
					</div>
					<div class="about-content">
						<div class="row">
							<div class="col-12 col-lg-12">
								<div class="about-content-left">
									<p class="highlight">We drive Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil beatae voluptatem nemo? Repudiandae, aut debitis.</p>
									<div class="row">
										<div class="col-lg-6">
											<div class="about-content-inner">
												<h5>Mission</h5>
												<p>We want to reduce carbon footprint and environmental impact and develop other energy business globally.</p>
											</div>
										</div>
										<div class="col-lg-6">
											<div class="about-content-inner">
												<h5>Vission</h5>
												<p>We are a diversified clean energy company with emphasis on power generation and energy services and wind solutions.</p>
											</div>
										</div>
									</div>                                        
									<router-link :to="{name:'About'}" class="main-btn primary">Read More</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-6 wow fadeInRight" data-wow-delay=".3s">
				<div class="about-img text-end">
					<img src="assets/img/about/about.png" alt="">
					<div class="about-counter">
						<div class="founder-number">
							<p>10+</p>
						</div>
						<p>Year of Experience</p>
					</div>
				</div>
			</div>

		</div>
	</div>
</div>

<!-- Service Section  -->

<div class="service-area gray-bg section-padding">
	<div class="container">
		<div class="row justify-content-center text-center">
			<div class="col-lg-9 wow fadeInUp" data-wow-delay=".3s">
				<div class="section-title">
					<h6>Our Service</h6>
					<h2>We are provide world class <br> Services & Solutions</h2>
					<p>There are some Lorem, ipsum dolor sit amet consectetur adipisicing elit. Minus minima incidunt est inventore ut laboriosam autem nisi nobis corrupti reprehenderit.</p>
				</div>
			</div>
		</div>
		<div class="service-item-wrap mt-40">
			<div class="container">
				<div class="row">
					<div class="col-lg-3 wow fadeInLeft" data-wow-delay=".2s" v-for="service in services.slice(0,4)" :key="service.id">
						<div class="service-single" :class="'service-'+service.id">
							<div class="service-icon">								
								<img :src="service.icon">
							</div>
							<div class="service-content">
								<h4>{{service.title}}</h4>
								<p>{{service.short_description}}</p>
								<router-link :to="{name:'SolarEnergy'}">Learn More <i class="las la-long-arrow-alt-right"></i></router-link>
							</div>
						</div>
					</div>					
				</div>
			</div>
		</div>
	</div>
</div>

<!-- Feature Section  -->
<div class="feature-section section-padding">
	<div class="container">
		<div class="row">
			<div class="col-lg-6 wow fadeInLeft" data-wow-delay=".2s">
				<div class="section-title">
					<h6>Core Feature</h6>   
					<h2>Save the Planet by <br> using Green Energy</h2>
				</div>
				<p>Soltrin provide Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam qui incidunt a sapiente quibusdam commodi!</p>
				<div class="feature-wrap">
					
						<div class="feature-inner" v-for="feature in features" :key="feature.id">
						<div class="feature-icon">
							<i class="las la-check-circle"></i>
						</div>
						<div class="feature-content" :class="'feature-'+feature.id">
							<h6>{{feature.title}}</h6>
							<p>{{feature.short_description}}</p>
						</div>
					
					</div>					
				</div>
			</div>
			<div class="col-lg-3 wow fadeInUp" data-wow-delay=".2s">
				<div class="feature-img">
					<img src="assets/img/story_img.jpg" alt="">
					<br><br>
					<img src="assets/img/story_img_2.jpg" alt="">
				</div>
			</div>
			<div class="col-lg-3 wow fadeInRight" data-wow-delay=".2s">
				<div class="counter-wrap">
					<div class="counter-number">
						<p class="counting">16</p>
						<h6>Presence</h6>
						<p>Countries accross five continents</p>
					</div>
					<div class="counter-number">
						<p class="counting">43</p>
						<h6>Gr. Total</h6>
						<p>Installed Solar Panels successfully</p>
					</div>
					<div class="counter-number">
						<p class="counting">102</p>
						<h6>Projects</h6>
						<p>Solar Solutions & Power Energy Operative</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- Portfolio Area-->

<div class="portfolio-area section-padding">
	<div class="container">
		<div class="row">
			<div class="col-lg-12 wow fadeInUp" data-wow-delay=".2s">
				<div class="section-title text-center">
					<h6>Latest Project</h6>
					<h2>We have done Solar <br>Power Projects</h2>
				</div>
				<ul class="port-menu recent text-center">
					<li data-filter="*" class="active">All</li>
					<li data-filter=".energy">Energy</li>
					<li data-filter=".solar">Solar Power</li>
					<li data-filter=".wind">Wind Turbine </li>
					<li data-filter=".power">Power Solutions</li>
				</ul>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12">
				<div class="portfolio-list recent">
					
					<div :class="'single-portfolio-item '+project.categories" v-for="project in projects" :key="project.id" :style="'background-image:url('+project.background_image+')'">
						<div class="details" :class="'project-'+project.id">
							<div class="info">
								<h5><router-link :to="{name:'ProjectDetails'}">{{project.title}}</router-link></h5>
								<router-link :to="{name:'ProjectDetails'}"><i class="las la-arrow-right"></i></router-link>
							</div>
						</div>
					</div>					
				</div>
			</div>
		</div>

		<div class="load-more-btn">
			<div class="main-btn">Load More</div>
		</div>

	</div>
</div>

<!-- CTA Area  -->

<div class="cta-area">
	<div class="container">
		<div class="row cta-content">
			<div class="col-lg-7 col-md-7 col-12 wow fadeInUp" data-wow-delay=".2s">
				<h2>Let's Started Your Project </h2>
				<p>Make an appointment get quote Lorem, ipsum dolor.</p>
			</div>
			<div class="col-lg-5 col-md-5 col-12 text-center wow fadeInUp" data-wow-delay=".3s">
				<router-link class="main-btn primary" :to="{name:'Price'}">Get a Quote</router-link>                                                        
			</div>
		</div>
	</div>
</div>

<!--Process Section -->

<div class="process-area section-padding">
	<div class="container">
		<div class="row">
			<div class="offset-lg-2 col-lg-8 text-center wow fadeInUp" data-wow-delay=".2s">
				<div class="section-title">
					<h6>Working Process</h6>
					<h2>How We Works with Solar</h2>
				</div>
			</div>
		</div>
		<div class="row process-item-wrap">
			<div class="col-lg-4 col-md-6 col-12 wow fadeInLeft" data-wow-delay=".3s" v-for="process in processes" :key="process.id">
				<div class="single-process-item" :class="'process-'+process.id">
					<div class="process-icon">
						<img :src="process.icon" alt="">
					</div>
					<div class="process-content">
						<h5>{{process.title}}</h5>
						<p>{{process.desc}}</p>
					</div>
				</div>
			</div>			
		</div>
	</div>
</div>

<!-- Testimonial Section  -->

<TestimonialOne/>

<!-- Blog Section -->

<div class="blog-area gray-bg section-padding">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-lg-12 wow fadeInUp" data-wow-delay=".2s">
				<div class="section-title text-center">
					<h6>News & Articles</h6>
					<h2>Whats going on <b>in our blogs?</b></h2>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-4 col-md-6 col-12 mb-100" v-for="blog in blogs.slice(0,3)" :key="blog.id">
				<div class="single-blog wow fadeInLeft" data-wow-delay=".3s">
					<router-link class="blog-img" :to="{name:'BlogDetails', params:{ id: blog.id }}">
						<img :src="blog.images.blog_image" :alt="blog.title">
					</router-link>                                                        					
					<div class="blog-content"> <span><a href="#">{{blog.tag}}</a></span>
						<h3><router-link :to="{name:'BlogDetails', params:{ id: blog.id }}">{{ blog.title }}</router-link></h3>
						<div class="blog-date">
							<p>{{ blog.date }}</p>
						</div>
						<router-link :to="{name:'BlogDetails', params:{ id: blog.id }}" class="read-more">Read More</router-link>
					</div>
				</div>
			</div>			
		</div>
	</div>
</div>

<!-- Footer Area -->


</template>

<script>

import axios from 'axios'

import HeaderTopbar from '@/components/HeaderTopbar.vue'
import NavbarHome1 from '@/components/NavbarHome1.vue'
import TestimonialOne from '@/components/TestimonialOne.vue'
import Search from '@/components/Search.vue'

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination, A11y, Autoplay } from "swiper";


// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";


export default {
    name: 'HomeOne',
    components : {
		HeaderTopbar, 
		Search,
		NavbarHome1, 
		Swiper, 
		SwiperSlide, 
		TestimonialOne
	},
	data(){
        return {
            modules: [Navigation, Pagination, A11y, Autoplay],
            swiperOptions: {
                breakpoints: {
                354: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                575: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                767: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                },

                990: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                1200: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                },
                },
            },	
			services : [], 		
			features : [], 		
			projects : [], 		
			processes : [], 		
			blogs : []			
        }
    },

	async mounted(){    

	//scrolling to top of the window
	window.scrollTo(0, 0); 
		
    //Isotope Filter
	$(".port-menu li").on("click", function () {
		var selector = $(this).attr("data-filter");

		$(".port-menu li").removeClass("active");

		$(this).addClass("active");

		$(".portfolio-list").isotope({
			filter: selector,
			percentPosition: true,
		});
	});

		let fetcheServicesdData = await axios.get("/assets/data/services.json");
        this.services = fetcheServicesdData.data.services;

		let fetcheFeatureData = await axios.get("/assets/data/features.json");
        this.features = fetcheFeatureData.data.features;

		let fetcheProjectData = await axios.get("/assets/data/projects.json");
        this.projects = fetcheProjectData.data.projects;

		let fetcheProcessData = await axios.get("/assets/data/processes.json");
        this.processes = fetcheProcessData.data.processes;

		let fetcheBlogsData = await axios.get("/assets/data/blogs.json");
        this.blogs = fetcheBlogsData.data.blogs;

	}
   
}
</script>