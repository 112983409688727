<template>

    <!-- Header Top  -->

    <HeaderTopbar/>

    <!-- Header Area -->

    <NavbarHome1/>

    <!-- Search Dropdown Area -->

    <Search/>

    <Breadcrumb 
        mainTitle="Team" 
        breadContent="We provide products Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse provident explicabo atque numquam enim debitis earum minus, perferendis sed ipsum." 
        subTitle="Team"
    />

    <!-- Team Area-->

    <div class="team-area gray-bg section-padding">
        <div class="container">            
            <div class="row">
                <div class="col-lg-3 col-md-6 wow fadeInLeft" data-wow-delay=".2s" v-for="team in teams" :key="team.id">
                    <div class="single-team-member" :class="'team-'+team.id">
                        <div class="team-member-bg" :style="'background-image:url('+team.background_image+')'">
                            <div class="team-content">
                                <div class="team-title">
                                    <a href="#">{{team.name}}</a>
                                </div>
                                <div class="team-subtitle">
                                    <p>{{team.designation}}</p>
                                </div>
                                <ul class="team-social">
                                    <li>
                                        <a href="#"><i class="la la-facebook" aria-hidden="true"></i> </a>
                                    </li>
                                    <li>
                                        <a href="#"><i class="la la-twitter" aria-hidden="true"></i> </a>
                                    </li>
                                    <li>
                                        <a href="#"><i class="la la-instagram" aria-hidden="true"></i> </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    </div>

    <!-- expertise section -->

    <div class="expertise-section dark-bg">
        <div class="row">
            <div class="col-lg-6">
                <div class="expertise-content">
                    <div class="section-title">
                        <h6 class="text-white">Our Expertise</h6>
                        <h2 class="text-white">We are building a Sustainable Future</h2>
                    </div>
                    <p class="text-white">
                        Our expertise officer Lorem ipsum dolor sit amet consectetur,
                        adipisicing elit. Omnis tenetur impedit vitae temporibus, nesciunt
                        perferendis?
                    </p>
                    <div class="progress-bar-area">
                        <div class="single-bar-item">
                            <h6 class="text-white">Maintenance</h6>
                            <div id="bar1" class="barfiller">
                                <span class="tip">75%</span>
                                <span class="fill" data-percentage="75"></span>
                            </div>
                        </div>

                        <div class="single-bar-item">
                            <h6 class="text-white">Consultant</h6>
                            <div id="bar2" class="barfiller">
                                <span class="tip">90%</span>
                                <span class="fill" data-percentage="90"></span>
                            </div>
                        </div>

                        <div class="single-bar-item">
                            <h6 class="text-white">Manufactures</h6>
                            <div id="bar3" class="barfiller">
                                <span class="tip">60%</span>
                                <span class="fill" data-percentage="60"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="expertise-img"></div>
                <div class="subscribed-wrap">
                    <div class="subscribe-form">
                        <h3>Join the community of over a million people</h3>
                        <form action="index.html">
                            <input type="email" placeholder="Your email" >
                            <button type="submit"><i class="las la-envelope"></i></button>
                        </form>                        
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Client Section  -->

    <div class="client-area pt-40 pb-40">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="client-wrap owl-carousel">
                        <div class="single-client">
                            <a href="#"><img src="assets/img/client/1.png" alt="" ></a>
                        </div>
                        <div class="single-client">
                            <a href="#"><img src="assets/img/client/2.png" alt="" ></a>
                        </div>
                        <div class="single-client">
                            <a href="#"><img src="assets/img/client/3.png" alt="" ></a>
                        </div>
                        <div class="single-client">
                            <a href="#"><img src="assets/img/client/4.png" alt="" ></a>
                        </div>
                        <div class="single-client">
                            <a href="#"><img src="assets/img/client/5.png" alt="" ></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import axios from 'axios'

import HeaderTopbar from '@/components/HeaderTopbar.vue'
import NavbarHome1 from '@/components/NavbarHome1.vue'
import Search from '@/components/Search.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'


export default {
    name: 'Team',
    components : {
        HeaderTopbar, 
        NavbarHome1, 
        Search,
        Breadcrumb
        },
	data(){
        return {            	
			teams: [],						
			
        }
    },

	async mounted(){  
        
        //scrolling to top of the window
        window.scrollTo(0, 0);
		
        $("#bar1").barfiller({
		barColor: "#007CFF",
		duration: 5000,
		});

		$("#bar2").barfiller({
		barColor: "#007CFF",
		duration: 5000,
		});

		$("#bar3").barfiller({
		barColor: "#007CFF",
		duration: 5000,
		});

        let fetcheTeamData = await axios.get("/assets/data/team.json");
        this.teams = fetcheTeamData.data.teams;

	}
   
}
</script>