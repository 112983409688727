<template>
    
    <!-- Breadcrumb Area -->

    <div class="breadcrumb-area bread-bg">
		<div class="overlay"></div>
		<div class="container">
			<div class="row">
				<div class="col-lg-8">
					<div class="breadcrumb-title">
						<h1>{{mainTitle}}</h1>
                        <p>{{breadContent}}</p>                        
					</div>
				</div>
			</div>
		</div>
	</div>

    <div class="breadcrumb-meta">
        <div class="container">
        <ul class="breadcrumb d-flex">
        <li class="breadcrumb-item"><a :to="{name:'HomeOne'}">Home</a></li>        
        <li class="breadcrumb-item active" aria-current="page">{{subTitle}}</li>
        </ul>
        </div>
    </div>
</template>

<script>
export default {
    props : ['mainTitle', 'breadContent','subTitle'],
    setup() {
        
    },
}
</script>