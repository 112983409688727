<template>
  <router-view></router-view>

  <Footer/>
</template>

<script>
// @ is an alias to /src
import Footer from '@/components/Footer.vue'

export default {
    name: 'app',
    components : { Footer},
}
</script>

<style>

</style>
