import { createRouter, createWebHistory } from 'vue-router'
import HomeOne from '../views/HomeOne.vue'
import HomeTwo from '../views/HomeTwo.vue'
import HomeThree from '../views/HomeThree.vue'
import About from '../views/About.vue'
import Services from '../views/Services.vue'
import Team from '../views/Team.vue'
import Process from '../views/Process.vue'
import Price from '../views/Price.vue'
import FAQ from '../views/FAQ.vue'
import RenewableEnergy from '../views/services/RenewableEnergy.vue'
import SolarEnergy from '../views/services/SolarEnergy.vue'
import WindTurbine from '../views/services/WindTurbine.vue'
import HydropowerPlant from '../views/services/HydropowerPlant.vue'
import SolarDevelopment from '../views/services/SolarDevelopment.vue'
import BatteryBackup from '../views/services/BatteryBackup.vue'
import Project from '../views/Project.vue'
import ProjectDetails from '../views/ProjectDetails.vue'
import Blog from '../views/Blog.vue'
import BlogDetails from '../views/BlogDetails.vue'
import Contact from '../views/Contact.vue'

const routes = [
  {
    path: '/',
    name: 'HomeOne',
    component: HomeOne,
    meta:{
      title: 'Soltrin | Solar & Renewable Energy VueJs Template', 
    },
  },

  {
    path: '/home2',
    name: 'HomeTwo',
    component: HomeTwo, 
  },

  {
    path: '/home3',
    name: 'HomeThree',
    component: HomeThree, 
  },

  {
    path: '/about',
    name: 'About',
    component: About, 
  },

  {
    path: '/services',
    name: 'Services',
    component: Services, 
  },

  {
    path: '/team',
    name: 'Team',
    component: Team, 
  },

  {
    path: '/process',
    name: 'Process',
    component: Process, 
  },

  {
    path: '/price',
    name: 'Price',
    component: Price, 
  },

  {
    path: '/FAQ',
    name: 'FAQ',
    component: FAQ, 
  },

  {
    path: '/RenewableEnergy',
    name: 'RenewableEnergy',
    component: RenewableEnergy, 
  },

  {
    path: '/SolarEnergy',
    name: 'SolarEnergy',
    component: SolarEnergy, 
  },

  {
    path: '/WindTurbine',
    name: 'WindTurbine',
    component: WindTurbine, 
  },

  {
    path: '/HydropowerPlant',
    name: 'HydropowerPlant',
    component: HydropowerPlant, 
  },

  {
    path: '/SolarDevelopment',
    name: 'SolarDevelopment',
    component: SolarDevelopment, 
  },

  {
    path: '/BatteryBackup',
    name: 'BatteryBackup',
    component: BatteryBackup, 
  },

  {
    path: '/project',
    name: 'Project',
    component: Project, 
  },

  {
    path: '/project-details',
    name: 'ProjectDetails',
    component: ProjectDetails, 
  },

  {
    path: '/blog',
    name: 'Blog',
    component: Blog, 
  },

  {
    path: '/blog-details/:id',
    name: 'BlogDetails',
    component: BlogDetails, 
  },

  {
    path: '/contact',
    name: 'Contact',
    component: Contact, 
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
