<template>

    <!-- Header Top  -->

    <HeaderTopbar/>

    <!-- Header Area -->

    <NavbarHome1/>

    <!-- Search Dropdown Area -->

    <Search/>

    <!-- Breadcrumb Area -->

    <Breadcrumb 
        mainTitle="Contact" 
        breadContent="We provide products Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse provident explicabo atque numquam enim debitis earum minus, perferendis sed ipsum." 
        subTitle="Contact"
    />

    <!-- Google Map Start-->
 	<div class="contact-page google-map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3359.2156438445377!2d-2.2936754376828103!3d53.4626665378156!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487bae72e7e47f69%3A0x6c930e96df4455fe!2sOld%20Trafford!5e0!3m2!1sen!2sbd!4v1661768864802!5m2!1sen!2sbd" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>

    <!-- Contact Section  -->

    <div class="contact-section section-padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-4">
                    <div class="contact-wrap">
                        <div class="contact-inner">
                            <div class="contact-headline">
                                <h3>Get Appointment starting a New Project?</h3>
                            </div>
                            <div class="contact-meta-info">
                                <div class="contact-single-info">
                                    <i class="las la-map-marker-alt"></i><h6>Address</h6>
                                    <p>248, Park Street Avenue, NY, USA </p>
                                </div>
                                <div class="contact-single-info">
                                    <i class="las la-phone"></i><h6>Phone</h6>
                                    <p>Mobile: +14+127893 </p>
                                    <p>Hotline: +1235678</p>
                                </div>
                                <div class="contact-single-info">
                                    <i class="las la-envelope"></i><h6>Phone</h6>
                                    <p>Mail: support@example.com </p>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-7 offset-lg-1">
                    <div class="section-title">
                        <h6>Let's Get In Touch</h6>
                        <h2>Send Us a Message</h2>
                        <p>Pleas put up a topic below reated to your inquiry. If you dont find what you need fill out our contract form. For all enquiries please mail us using the below</p>
                    </div>
                    <div class="contact_form">
                        <form action="assets/inc/sendemail.php" class="comment-one_form contact-form-validated" novalidate="novalidate">
                            <div class="row">
                                <div class="col-xl-6">
                                    <div>
                                        <input type="text" placeholder="Your Name" name="name">
                                    </div>
                                </div>
                                <div class="col-xl-6">
                                    <div>
                                        <input type="email" placeholder="Email Address" name="email">
                                    </div>
                                </div>
                                <div class="col-xl-6">
                                    <div>
                                        <input type="text" placeholder="Phone Number" name="phone">
                                    </div>
                                </div>
                                <div class="col-xl-6">
                                    <div>
                                        <input type="email" placeholder="Subject" name="Subject">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-12">
                                    <div>
                                        <textarea name="message" placeholder="Write a Message"></textarea>
                                    </div>
                                    <button type="submit" class="main-btn">send a message</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- CTA Area  -->

 	<div class="cta-area">
        <div class="container">
            <div class="row cta-content">
                <div class="col-lg-7 col-md-7 col-12 wow fadeInUp" data-wow-delay=".2s">
                    <h2>Let's Started Your Project </h2>
                    <p>Make an appointment get quote Lorem, ipsum dolor.</p>
                </div>
                <div class="col-lg-5 col-md-5 col-12 text-center wow fadeInUp" data-wow-delay=".3s">
                    <router-link :to="{name:'Price'}" class="main-btn">Get a Quote</router-link>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import HeaderTopbar from '@/components/HeaderTopbar.vue'
import NavbarHome1 from '@/components/NavbarHome1.vue'
import Search from '@/components/Search.vue'
import TestimonialOne from '@/components/TestimonialOne.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'


export default {
    name: 'Team',
    components : {HeaderTopbar, NavbarHome1, Search, TestimonialOne, Breadcrumb},
	data(){
        return {
            	
			
			
        }
    },

	async mounted(){    
		
        //scrolling to top of the window
        window.scrollTo(0, 0);  

	}
   
}
</script>