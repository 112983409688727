<template>

    <!-- Header Top  -->

    <HeaderTopbar/>

    <!-- Header Area -->

    <NavbarHome1/>

    <!-- Search Dropdown Area -->

    <Search/>

        <!-- Breadcrumb Area -->

        <div class="breadcrumb-area project-bg">
		<div class="overlay"></div>
		<div class="container">
			<div class="row">
				<div class="col-lg-6">
					<div class="breadcrumb-title">
						<h1>Our Portfolio</h1>
                        <p>We provide products Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse provident explicabo atque numquam enim debitis earum minus, perferendis sed ipsum.</p>
                        <a href="price.html" class="main-btn primary">Our Plans</a>						
					</div>
				</div>
			</div>
		</div>
	</div>

    <div class="breadcrumb-meta">
        <div class="container">
        <ul class="breadcrumb d-flex">
        <li class="breadcrumb-item"><a href="index.html">Home</a></li>        
        <li class="breadcrumb-item active" aria-current="page">Projects</li>
        </ul>
        </div>
    </div>

    <!-- Portfolio Area-->

<div class="portfolio-area section-padding">
	<div class="container">
		<div class="row">
			<div class="col-lg-12 wow fadeInUp" data-wow-delay=".2s">				
				<ul class="port-menu recent text-center">
					<li data-filter="*" class="active">All</li>
					<li data-filter=".energy">Energy</li>
					<li data-filter=".solar">Solar Power</li>
					<li data-filter=".wind">Wind Turbine </li>
					<li data-filter=".power">Power Solutions</li>
				</ul>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12">
				<div class="portfolio-list recent">
					
					<div :class="'single-portfolio-item '+project.categories" v-for="project in projects" :key="project.id" :style="'background-image:url('+project.background_image+')'">
						<div class="details" :class="'project-'+project.id">
							<div class="info">
								<h5><router-link :to="{name:'ProjectDetails'}">{{project.title}}</router-link></h5>
								<router-link :to="{name:'ProjectDetails'}"><i class="las la-arrow-right"></i></router-link>
							</div>
						</div>
					</div>					
				</div>
			</div>
		</div>

		<div class="load-more-btn">
			<div class="main-btn">Load More</div>
		</div>

	</div>
</div>

</template>

<script>

import axios from 'axios'

import HeaderTopbar from '@/components/HeaderTopbar.vue'
import NavbarHome1 from '@/components/NavbarHome1.vue'
import Search from '@/components/Search.vue'
import TestimonialOne from '@/components/TestimonialOne.vue'

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination, A11y, Autoplay } from "swiper";


// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";


export default {
    name: 'Services',
    components : {HeaderTopbar, NavbarHome1, Search, Swiper, SwiperSlide, TestimonialOne},
	data(){
        return {
            modules: [Navigation, Pagination, A11y, Autoplay],
            swiperOptions: {
                breakpoints: {
                354: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                575: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                767: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                },

                990: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                1200: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                },
                },
            },				
			projects : [], 		
			
			
        }
    },

	async mounted(){    

    //scrolling to top of the window
    window.scrollTo(0, 0); 
		
    //Isotope Filter
	$(".port-menu li").on("click", function () {
		var selector = $(this).attr("data-filter");

		$(".port-menu li").removeClass("active");

		$(this).addClass("active");

		$(".portfolio-list").isotope({
			filter: selector,
			percentPosition: true,
		});
	});


		let fetcheProjectData = await axios.get("/assets/data/projects.json");
        this.projects = fetcheProjectData.data.projects;


	}
   
}
</script>