<template>

<!-- Header Top  -->

<HeaderTopbar/>

<!-- Header Area -->

 <NavbarHome1/>

<!-- Search Dropdown Area -->

<div class="search-popup">
	<span class="search-back-drop"></span>

	<div class="search-inner">
		<div class="container">
			<div class="upper-text">
				<div class="text">Search for anything.</div>
				<button class="close-search"><span class="la la-times"></span></button>
			</div>

			<form method="post" action="index.html">
				<div class="form-group">
					<input type="search" name="search-field" value="" placeholder="Search..." required="">
					<button type="submit"><i class="la la-search"></i></button>
				</div>
			</form>
		</div>
	</div>
</div>

    <!-- Hero Area -->

    <div class="homepage-slides">
        <swiper
                :modules="modules"
                :slides-per-view="1"
                :space-between="0"
                navigation
                :pagination="{ clickable: true }"
                :autoplay="{ delay: 4000 }"
        >
        
            <swiper-slide>
                <div class="single-slide-item hero-area-bg-5">
                <div class="image-layer">
                    <div class="overlay"></div>
                </div>
                <div class="hero-area-content">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-8 wow fadeInUp animated" data-wow-delay=".2s">
                                <div class="section-title">
                                    <h1>Power Your<br> Perfect Way</h1>
                                </div>
                                <p>Global warming solar revolution lorem ipsum dolor sit, amet consectetur adipisicing elit. <br> Consequatur odit iure incidunt minus recusandae minima laboriosam adipisci.</p>
                                <router-link :to="{name:'About'}" class="main-btn primary">Learn More</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </swiper-slide>
        

            <swiper-slide>
                <div class="single-slide-item hero-area-bg-6">
                <div class="image-layer" style="background-image: url(assets/img/slider/slide-2.jpg);">
                    <div class="overlay"></div>
                </div>
                <div class="hero-area-content">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-8 wow fadeInUp animated" data-wow-delay=".2s">
                                <div class="section-title">
                                    <h1>Ready For <br> a Challenge</h1>
                                </div>
                                <p>Global warming solar revolution lorem ipsum dolor sit, amet consectetur adipisicing elit. <br> Consequatur odit iure incidunt minus recusandae minima laboriosam adipisci.</p>
                                <router-link :to="{name:'About'}" class="main-btn primary">Learn More</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </swiper-slide>
    </swiper>

    </div>

    <!-- About Section  -->

    <div id="about-3" class="about-section section-padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                    <div class="section-title">
                        <h6>Our Philosophy</h6>
                        <h2>Vision For the Future</h2>
                        <p>Soltrin is a Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati, laboriosam non? Veniam adipisci commodi nemo accusamus quasi in vel itaque?</p>
                    </div>
                </div>
                <div class="col-lg-5 offset-lg-1 wow fadeInUp" data-wow-delay=".3s">
                    <div class="philoshopy-content">
                        <h5>Why Renewable Energy</h5>
                        <p>Generating energy that produces no greenhouse gas emissions from fossil fuels and reduces some types of air pollution. Diversifying energy supply and reducing dependence on imported fuels.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 wow fadeInLeft" data-wow-delay=".2s">
                    <div class="about-thumb">
                    </div>
                </div>
                <div class="col-lg-3 wow fadeInLeft" data-wow-delay=".4s">
                    <div class="philoshopy-content">
                        <h5>Mission</h5>
                        <p>Powering a sustainable energy future one project at a time.</p>
                        <p>We want to reduce carbon footprint and environmental impact and develop other business and dissiminate new energy solutions globally.</p>
                    </div>
                </div>
                <div class="col-lg-3 wow fadeInLeft" data-wow-delay=".6s">
                    <div class="philoshopy-content">
                        <h5>Vission</h5>
                        <p>We are a diversified clean energy company with emphasis on power generation and energy services. </p>
                        <p>Our vission is to support every idea that facilities life in local communities in the world.</p>
                    </div>
                </div>
                <div class="col-lg-3 wow fadeInLeft" data-wow-delay=".8s">
                    <div class="philoshopy-content">
                        <h5>Technology</h5>
                        <p>To find new innovative energy solutions and increase opinion formation in the world.</p>
                    </div>
                    <a href="about.html" class="main-btn about-us-btn mt-20">Discover More</a>
                </div>


            </div>
        </div>
    </div>

    <!-- Process Section -->

    <div class="working-process section-padding pt-0">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 text-center wow fadeInUp" data-wow-delay=".3s">
                    <div class="section-title">
                        <h6>Working Process</h6>
                        <h2>How to get started with Solar</h2>
                        <p>Soltrin is a commonly use easy processing system. <br> Firstly, location is selected your desired area then planning solor type.</p>
                    </div>
                    <div class="working-process-image">
                        <img src="assets/img/process.jpg" alt="">                        
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Why Choose Us & FAQ Section  -->

    <div class="why-choose-us-area gray-bg section-padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                    <div class="why-choose-us-wrap">
                        <div class="section-title">
                            <h6>Why Choose Us</h6>
                            <h2>Best Yet Affordable Solar Power Plant</h2>
                            <p>Our service overall customer solid based choice. Solar installation, processing & finalizing works smoothly done by our expertise. So working process & installing overall satisfy our customers. </p>
                        </div>

                        <div class="feature-item-wrap">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="single-feature-item">
                                        <div class="feature-icon-wrap">
                                            <img src="assets/img/feature/1.png" alt="">
                                        </div>
                                        <div class="feature-content-wrap">
                                            <h6>Affordable Cost</h6>
                                            <p>We are going Lorem ipsum dolor sit amet.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="single-feature-item">
                                        <div class="feature-icon-wrap">
                                            <img src="assets/img/feature/3.png" alt="">
                                        </div>
                                        <div class="feature-content-wrap">
                                            <h6>Quality of Work</h6>
                                            <p>We are going Lorem ipsum dolor sit amet.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 wow fadeInDown" data-wow-delay=".3s">
                    <div class="accordion faqs" id="accordionFaq">
                        <div class="card" v-for="faq in faqs.slice(0,6)" :key="faq.id">                            
                            <div class="card-header" id="heading1">
                                <h5 class="mb-0 subtitle">
                                    <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse-'+faq.id" aria-expanded="false" :aria-controls="'collapse-'+faq.id">
                                        {{faq.question}}
                                    </button>
                                </h5>
                            </div>

                            <div :id="'collapse-'+faq.id" class="collapse" :aria-labelledby="'heading-'+faq.id" data-parent="#accordionFaq">
                                <div class="card-body">
                                    <div class="content">
                                        <p>{{faq.answer}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Service Section  -->

    <div id="service-3" class="service-area section-padding">
        <div class="container">
            <div class="row justify-content-center text-center">
                <div class="col-lg-8 wow fadeInUp" data-wow-delay=".3s">
                    <div class="section-title">
                        <h6>Our Service</h6>
                        <h2>We are provide world class Services & Solutions</h2>
                        <p>There are available service Solar Panel, Wind Turbine, Solar Accessories & Repairing. In additional maintenance of Solar Energy is costly cheap. </p>
                    </div>
                </div>
            </div>
            <div class="service-item-wrap mt-40">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3 wow fadeInLeft" data-wow-delay=".2s" v-for="service in services.slice(0,4)" :key="service.id">
						<div class="service-single" :class="'service-'+service.id">
							<div class="service-icon">								
								<img :src="service.icon">
							</div>
							<div class="service-content">
								<h4>{{service.title}}</h4>
								<p>{{service.short_description}}</p>
								<router-link :to="{name:'SolarEnergy'}">Learn More <i class="las la-long-arrow-alt-right"></i></router-link>
							</div>
						</div>
					</div>					
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Achievement Area -->

    <div class="skill-area">
        <div class="skill-bg bg-cover">
            <div class="overlay">
            </div>
        </div>
        <div class="skill-content">
            <div class="section-title wow fadeInUp" data-wow-delay=".3s">
                <h6>Our Achievement</h6>
                <h2>Our Brilliant <b><br>Creativity</b> & <b>Skill</b></h2>
            </div>
            <p>There are planty industrial solution the filed. One of the best civil work in downtown have done by expert contractor.</p>
            <div class="row gx-0">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="single-count-box one">
                        <p class="count-number"><span>1037</span></p>
                        <h6>Project Completed</h6>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="single-count-box two">
                        <p class="count-number"><span>156</span></p>
                        <h6>Qualified Workers</h6>
                    </div>
                </div>
            </div>
            <div class="row no-gutters">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="single-count-box three">
                        <p class="count-number"><span>750</span>+</p>
                        <h6>Work Order</h6>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="single-count-box four">
                        <p class="count-number"><span>30</span>+</p>
                        <h6>Worldwide Branches</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--Testimonial Area -->

    <div class="testimonial-section gray-bg section-padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 text-center wow fadeInUp" data-wow-delay=".3s">
                    <div class="section-title">
                        <h6>Testimonials</h6>
                        <h2>Our Happy <b>Customers</b> Says</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="single-testimonial-box">
                        <div class="testimonial-text">
                            <i class="las la-quote-left"></i>
                            <p>Soltrin has been absolutely the best to work with. Their attention to detail and customer support was amazing!! They were fantastic through the entire purchase process.</p>
                        </div>
                        <div class="testimonial-bottom">
                            <img src="assets/img/testimonial/1.jpg" alt="">
                            <p class="author-desc">Mirinda Gomez<span>Co-founder, Delta Logistic</span></p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="single-testimonial-box active">
                        <div class="testimonial-text">
                            <i class="las la-quote-left"></i>
                            <p>Soltrin has been absolutely the best to work with. Their attention to detail and customer support was amazing!! They were fantastic through the entire purchase process.</p>
                        </div>
                        <div class="testimonial-bottom">
                            <img src="assets/img/testimonial/2.jpg" alt="">
                            <p class="author-desc">Bryan Adams<span>Chairman, Alpha Inc. </span></p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="single-testimonial-box">
                        <div class="testimonial-text">
                            <i class="las la-quote-left"></i>
                            <p>Soltrin has been absolutely the best to work with. Their attention to detail and customer support was amazing!! They were fantastic through the entire purchase process.</p>
                        </div>
                        <div class="testimonial-bottom">
                            <img src="assets/img/testimonial/3.jpg" alt="">
                            <p class="author-desc">Franklin Josehp<span>CEO, Glory Ind Ltd.</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- CTA Area  -->

    <div class="cta-area">
        <div class="container">
            <div class="row cta-content">
                <div class="col-lg-7 col-md-7 col-12 wow fadeInUp" data-wow-delay=".2s">
                    <h2>Let's Started Your Project </h2>
                    <p>Make an appointment get quote Lorem, ipsum dolor.</p>
                </div>
                <div class="col-lg-5 col-md-5 col-12 text-center wow fadeInUp" data-wow-delay=".3s">
                    <router-link :to="{name:'Price'}" class="main-btn">Get a Quote</router-link>
                </div>
            </div>
        </div>
    </div>

    <!-- Blog Section -->

    <div class="blog-area gray-bg section-padding">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-lg-12 wow fadeInUp" data-wow-delay=".2s">
				<div class="section-title text-center">
					<h6>News & Articles</h6>
					<h2>Whats going on <b>in our blogs?</b></h2>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-4 col-md-6 col-12 mb-100" v-for="blog in blogs.slice(0,3)" :key="blog.id">
				<div class="single-blog wow fadeInLeft" data-wow-delay=".3s">
					<router-link class="blog-img" :to="{name:'BlogDetails', params:{ id: blog.id }}">
						<img :src="blog.images.blog_image" :alt="blog.title">
					</router-link>                                                        					
					<div class="blog-content"> <span><a href="#">{{blog.tag}}</a></span>
						<h3><router-link :to="{name:'BlogDetails', params:{ id: blog.id }}">{{ blog.title }}</router-link></h3>
						<div class="blog-date">
							<p>{{ blog.date }}</p>
						</div>
						<router-link :to="{name:'BlogDetails', params:{ id: blog.id }}" class="read-more">Read More</router-link>
					</div>
				</div>
			</div>			
		</div>
	</div>
</div>

</template>

<script>

import axios from 'axios'
import HeaderTopbar from '@/components/HeaderTopbar.vue'
import NavbarHome1 from '@/components/NavbarHome1.vue'


// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination, A11y, Autoplay } from "swiper";


// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";


export default {
    name: 'HomeOne',
    components : {HeaderTopbar, NavbarHome1, Swiper, SwiperSlide},
	data(){
        return {
            modules: [Navigation, Pagination, A11y, Autoplay],
            swiperOptions: {
                breakpoints: {
                354: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                575: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                767: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                },

                990: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                1200: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                },
                },
            },
            services: [],							
            faqs: [],							
            blogs: [],							
        }
    },

	async mounted(){    

    //scrolling to top of the window
    window.scrollTo(0, 0); 
		
	// Active Bacground Color  

	$(".single-testimonial-box").on("mouseover", function () {
		$(".single-testimonial-box").removeClass("active");
		$(this).addClass("active");
	});


		let fetcheServicesdData = await axios.get("/assets/data/services.json");
        this.services = fetcheServicesdData.data.services;

		let fetcheFeatureData = await axios.get("/assets/data/features.json");
        this.features = fetcheFeatureData.data.features;

		let fetcheProjectData = await axios.get("/assets/data/projects.json");
        this.projects = fetcheProjectData.data.projects;

		let fetcheProcessData = await axios.get("/assets/data/processes.json");
        this.processes = fetcheProcessData.data.processes;

		let fetcheBlogsData = await axios.get("/assets/data/blogs.json");
        this.blogs = fetcheBlogsData.data.blogs;

		let fetcheFaqData = await axios.get("/assets/data/faq.json");
        this.faqs = fetcheFaqData.data.faqs;

	}
   
}
</script>