<template>
    <div class="header-top">
        <div class="container custom-container">
            <div class="row">
                <div class="col-xl-6 col-lg-5 col-md-12 col-xs-12">
                    <div class="contact-info">
                        <i class="las la-phone"></i> Contact Us: {{contactInfo.phone}}
                    </div>
                </div>
                <div class="col-xl-6 col-lg-7 col-md-12 col-xs-12 text-end">
                    <div class="header-right">
                        <div class="header-top-content">
                            <p>Find out the cost of solar of your home <router-link :to="{name:'About'}">Learn More</router-link></p>
                        </div>
                        <div class="social-area">
                            <a href=""><i class="lab la-facebook-f"></i></a>
                            <a href=""><i class="lab la-twitter"></i></a>
                            <a href=""><i class="lab la-youtube"></i></a>
                            <a href=""><i class="lab la-instagram"></i></a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data(){
        return {
            contactInfo : [],         
        }
    },

    async mounted(){
        let fetchedData = await axios.get("/assets/data/contactInfo.json");
        this.contactInfo = fetchedData.data.contactInfo;

    },

}
</script>