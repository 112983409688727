<template>
    <!-- Header Area -->

    <div class="header-area absolute-header">
        <div class="sticky-area">
            <div class="navigation">
                <div class="container-fluid">
                    <div class="header-inner-box">
                        <div class="logo">
                            <router-link class="navbar-Solar" :to="{name:'HomeTwo'}"><img src="/assets/img/logo-white.png" alt=""></router-link>
                        </div>

                        <div class="main-menu">
                            <nav class="navbar navbar-expand-lg">
                                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span class="navbar-toggler-icon"></span>
                                    <span class="navbar-toggler-icon"></span>
                                    <span class="navbar-toggler-icon"></span>
                                </button>

                                <div class="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
                                    <ul class="navbar-nav m-auto">
                                        <li class="nav-item">
                                            <a class="nav-link active" href="#">Home
                                                <span class="sub-nav-toggler"> </span>
                                            </a>
                                            <ul class="sub-menu">                                                
                                                <li><router-link class="router-link" :to="{name:'HomeOne'}">Home-01</router-link></li>
                                                <li><router-link class="router-link" :to="{name:'HomeTwo'}">Home-02</router-link></li>
                                                <li><router-link class="router-link" :to="{name:'HomeThree'}">Home-03</router-link></li>
                                            </ul>
                                        </li>

                                        <li class="nav-item">
                                            <a class="nav-link" href="#">Pages
                                                <span class="sub-nav-toggler"> </span>
                                            </a>
                                            <ul class="sub-menu">
                                                <li><router-link :to="{name:'About'}">About Us</router-link></li>
                                                <li><router-link :to="{name:'Services'}">Service</router-link></li>
                                                <li><router-link :to="{name:'Team'}">Our Team</router-link></li>                                                
                                                <li><router-link :to="{name:'Process'}">Process</router-link></li>                                                                                                
                                                <li><router-link :to="{name:'Price'}">Price</router-link></li>                                                                                                
                                                <li><router-link :to="{name:'FAQ'}">FAQ's</router-link></li>                                                                                                                                                
                                            </ul>
                                        </li>

                                        <li class="nav-item">
                                            <a class="nav-link" href="#">Services
                                                <span class="sub-nav-toggler"> </span>
                                            </a>
                                            <ul class="sub-menu">
                                                <li><router-link :to="{name:'RenewableEnergy'}">Renewable Energy</router-link></li>                                                
                                                <li><router-link :to="{name:'SolarEnergy'}">Solar Energy</router-link></li>                                                
                                                <li><router-link :to="{name:'WindTurbine'}">Wind Turbine</router-link></li>                                                
                                                <li><router-link :to="{name:'HydropowerPlant'}">Hydropower Plant</router-link></li>                                                                                                
                                                <li><router-link :to="{name:'SolarDevelopment'}">Solar Development</router-link></li>                                                 
                                                <li><router-link :to="{name:'BatteryBackup'}">Battery Backup</router-link></li>                                                                                                 
                                            </ul>
                                        </li>

                                        <li class="nav-item">
                                            <a class="nav-link" href="#">Project
                                                <span class="sub-nav-toggler"> </span>
                                            </a>
                                            <ul class="sub-menu">
                                                <li><router-link :to="{name:'Project'}">Project</router-link></li>                                                                                                
                                                <li><router-link :to="{name:'ProjectDetails'}">Project Details</router-link></li>                                                                                                
                                            </ul>
                                        </li>

                                        <li class="nav-item">
                                            <a class="nav-link" href="#">Blog
                                                <span class="sub-nav-toggler"> </span>
                                            </a>
                                            <ul class="sub-menu">
                                                <li><router-link :to="{name:'Blog'}">Blog</router-link></li>
                                                <li><router-link :to="{name:'BlogDetails', params:{ id: 1 }}">Blog Details</router-link></li>                                                
                                            </ul>
                                        </li>
                                        <li class="nav-item">
                                            <router-link class="nav-link" :to="{name:'Contact'}">Contact</router-link>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>

                        <div class="icon-wrapper">
                            <div class="search-icon search-trigger">
                                <i class="las la-search"></i>
                            </div>
                            <div class="shopping-cart">
                                <i class="las la-shopping-cart"></i>
                            </div>
                        </div>

                        <div class="contact-info">
                            <i class="las la-phone"></i> Call Us: <br>
                            <span>+80 125 880 266</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
	mounted(){

	//Sticky Area
	$(".sticky-area").sticky({
		topSpacing: 0,
	});

		// Mobile Menu
        $(".navbar-toggler").on("click", function () {
            $(this).toggleClass("active");
        });
    
        $(".navbar-nav li a").on("click", function () {
            $(".sub-nav-toggler").removeClass("active");
        });
    
        var subMenu = $(".navbar-nav  .sub-menu");
        if (subMenu.length) {
            
            subMenu
                .parent("li")
                .children("a")
                .append(function () {
                    return '<button class="sub-nav-toggler"> <i class="las la-angle-down"></i> </button>';
                });
    
            var subMenuToggler = $(".navbar-nav .sub-nav-toggler");
    
            subMenuToggler.on("click", function () {
                $(this).parent().parent().children(".sub-menu").slideToggle();
                return false;
            });
        }
	},
    setup() {
        
    },
}
</script>