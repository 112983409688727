<template>

    <!-- Header Top  -->

    <HeaderTopbar/>

    <!-- Header Area -->

    <NavbarHome1/>

    <!-- Search Dropdown Area -->

    <Search/>

<!-- Breadcrumb Area -->

<Breadcrumb 
        mainTitle="Blog" 
        breadContent="We provide products Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse provident explicabo atque numquam enim debitis earum minus, perferendis sed ipsum." 
        subTitle="Blog"
    />

    <!-- Blog Section -->

    <div class="blog-area gray-bg section-padding">
	<div class="container">
		
		<div class="row">
			<div class="col-lg-4 col-md-6 col-12 mb-100" v-for="blog in blogs" :key="blog.id">
				<div class="single-blog wow fadeInLeft" data-wow-delay=".3s">
					<router-link class="blog-img" :to="{name:'BlogDetails', params:{ id: blog.id }}">
						<img :src="blog.images.blog_image" :alt="blog.title">
					</router-link>                                                        					
					<div class="blog-content"> <span><a href="#">{{blog.tag}}</a></span>
						<h3><router-link :to="{name:'BlogDetails', params:{ id: blog.id }}">{{ blog.title }}</router-link></h3>
						<div class="blog-date">
							<p>{{ blog.date }}</p>
						</div>
						<router-link :to="{name:'BlogDetails', params:{ id: blog.id }}" class="read-more">Read More</router-link>
					</div>
				</div>
			</div>			
		</div>
	</div>
</div>

</template>

<script>

import axios from 'axios'

import HeaderTopbar from '@/components/HeaderTopbar.vue'
import NavbarHome1 from '@/components/NavbarHome1.vue'
import Search from '@/components/Search.vue'
import TestimonialOne from '@/components/TestimonialOne.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination, A11y, Autoplay } from "swiper";


// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";



export default {
    name: 'Blog',
    components : {
        HeaderTopbar, 
        NavbarHome1, 
        Search,
        Swiper, 
        SwiperSlide, 
        TestimonialOne,
        Breadcrumb
    },
	data(){
        return { 
            modules: [Navigation, Pagination, A11y, Autoplay],
            swiperOptions: {
                breakpoints: {
                354: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                575: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                767: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                },

                990: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                1200: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                },
                },
            },           	
			blogs : []
			
        }
    },

	async mounted(){   
        
        //scrolling to top of the window
        window.scrollTo(0, 0);          		
		

		let fetcheBlogsData = await axios.get("/assets/data/blogs.json");
        this.blogs = fetcheBlogsData.data.blogs;

	}
   
}
</script>