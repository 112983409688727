<template>

    <!-- Header Top  -->

    <HeaderTopbar/>

    <!-- Header Area -->

    <NavbarHome1/>

    <!-- Search Dropdown Area -->

    <Search/>

	<!-- Breadcrumb Area -->

	<div class="breadcrumb-area project-details">
		<div class="overlay"></div>
		<div class="container">
			<div class="row">
				<div class="col-lg-6">
					<div class="breadcrumb-title">
						<h1>Project Details</h1>
                        <p>We provide products Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse provident explicabo atque numquam enim debitis earum minus, perferendis sed ipsum.</p>
                        <a href="price.html" class="main-btn primary">Our Plans</a>						
					</div>
				</div>
			</div>
		</div>
	</div>

    <div class="breadcrumb-meta">
        <div class="container">
        <ul class="breadcrumb d-flex">
        <li class="breadcrumb-item"><a href="index.html">Home</a></li>        
        <li class="breadcrumb-item active" aria-current="page">Projects Details</li>
        </ul>
        </div>
    </div>

    <!-- project details-->
    <div id="project-page">
        <div class="project_details-section section-padding" v-for="single_project in single_projects" :key="single_project.id">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="single-content-wrapper">
                            <div class="single-image-wrapper">
                                <img :src="single_project.images.feature_image" alt="feature_image">
                            </div>
                            <div class="project-details-box">
                                <div class="project-details-box-title">
                                    <h4>Project Info</h4>									
                                </div>	                            
                                    <ul class="detail-box-list">
                                        <li>
                                            <div class="list-details">												
                                                <i class="las la-tasks"></i>
                                                <h5>Project <span>{{single_project.project_info_title}}</span> </h5>												
                                            </div>
                                        </li>
                                        
                                        <li>
                                            <div class="list-details">												
                                                <i class="las la-film"></i>
                                                <h5>Categories <span>{{single_project.project_category}}</span> </h5>												
                                            </div>
                                        </li>
                                        <li>
                                            <div class="list-details">												
                                                <i class="las la-calendar"></i>
                                                <h5>Date <span>{{single_project.project_date}}</span> </h5>												
                                            </div>
                                        </li>										
                                        <li>
                                            <div class="list-details">												
                                                <i class="las la-user"></i>
                                                <h5>Client <span>{{single_project.project_client}}</span> </h5>												
                                            </div>
                                        </li>
                                    </ul>                            
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="single-content-area">
                                    <h4>{{single_project.project_intro_title}}</h4>
                                    <p>{{single_project.project_intro_desc}}</p>
                                    <br>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-50">
                            <div class="col-xl-4 col-lg-4 col-md-6 col-xs-12">
                                <div class="single-img-wrapper">
                                    <img :src="single_project.images.image_1" alt="image_one">
                                </div>																
                            </div>
                            
                            <div class="col-xl-4 col-lg-4 col-md-6 col-xs-12">
                                <div class="single-img-wrapper">
                                    <img :src="single_project.images.image_2" alt="image_two">
                                </div>																
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6 col-xs-12">
                                <div class="single-img-wrapper">
                                    <img :src="single_project.images.image_3" alt="image_three">
                                </div>																
                            </div>
                        </div>

                        <div class="row">
                            <div class="single-content-area">
                                <h4>{{single_project.project_subtitle_one}}</h4>
                                <p>Solar panels act as a way to mount a series of solar cells so that their unique properties can be used to generate electricity. Individual cells absorb photons from the Sun, which results in the production of an electric current in the cell through a phenomenon known as the photovoltaic effect. When installing a solar panel, the proper orientation is chosen so that the solar panel faces in a direction that is most suitable for the specific application. This is most often to produce the maximum annual energy, but is not always the case.</p>
                            </div>
                        </div>
                        
                        <div class="row project-solutions mt-50">
                            <div class="col-xl-6">
                                <div class="single-content-area">
                                    <h4>{{single_project.project_subtitle_two}}</h4>
                                </div>										
                                <ul class="project-solutions-list">
                                        
                                    <li><i class="feature-icon las la-check-circle"></i>{{single_project.solutions_list.list_1}}</li>                                
                                    <li><i class="feature-icon las la-check-circle"></i>{{single_project.solutions_list.list_2}}</li>                                
                                    <li><i class="feature-icon las la-check-circle"></i>{{single_project.solutions_list.list_3}}</li>                               
                                    <li><i class="feature-icon las la-check-circle"></i>{{single_project.solutions_list.list_4}}</li>                               
                                    <li><i class="feature-icon las la-check-circle"></i>{{single_project.solutions_list.list_5}}</li>                               
                                    <li><i class="feature-icon las la-check-circle"></i>{{single_project.solutions_list.list_6}}</li>                               
                                    <li><i class="feature-icon las la-check-circle"></i>{{single_project.solutions_list.list_7}}</li>                                                                                                                                                                                           
                                </ul>
                            </div>							
                            <div class="col-xl-6">
                                <img :src="single_project.images.wide_image" alt="wide_image">
                                <div class="pop-up-video">
                                    <a href="https://www.youtube.com/watch?v=yFwGpiCs3ss" class="video-play-btn mfp-iframe">
                                        <i class="las la-play"></i> <span></span>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="single-content-area">
                            <h4>{{single_project.project_subtitle_three}}</h4>
                            <p>{{single_project.project_desc_three}}</p>
                        </div>                                    
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- CTA Area  -->

 	<div class="cta-area">
        <div class="container">
            <div class="row cta-content">
                <div class="col-lg-7 col-md-7 col-12 wow fadeInUp" data-wow-delay=".2s">
                    <h2>Let's Started Your Project </h2>
                    <p>Make an appointment get quote Lorem, ipsum dolor.</p>
                </div>
                <div class="col-lg-5 col-md-5 col-12 text-center wow fadeInUp" data-wow-delay=".3s">
                    <router-link :to="{name:'Price'}" class="main-btn">Get a Quote</router-link>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import axios from 'axios'

import HeaderTopbar from '@/components/HeaderTopbar.vue'
import NavbarHome1 from '@/components/NavbarHome1.vue'
import Search from '@/components/Search.vue'
import TestimonialOne from '@/components/TestimonialOne.vue'

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination, A11y, Autoplay } from "swiper";


// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";


export default {
    name: 'Services',
    components : {HeaderTopbar, NavbarHome1, Search, Swiper, SwiperSlide, TestimonialOne},
	data(){
        return {
            modules: [Navigation, Pagination, A11y, Autoplay],
            swiperOptions: {
                breakpoints: {
                354: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                575: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                767: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                },

                990: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                1200: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                },
                },
            },				
			single_projects : [], 		
			
        }
    },

	async mounted(){    
		
		//scrolling to top of the window
        window.scrollTo(0, 0); 

		let fetcheSingleProjectData = await axios.get("/assets/data/single_projects.json");
        this.single_projects = fetcheSingleProjectData.data.single_projects;

		

	}
   
}
</script>