<template>

    <!-- Header Top  -->

    <HeaderTopbar/>

    <!-- Header Area -->

    <NavbarHome1/>

    <!-- Search Dropdown Area -->

    <Search/>

    <!-- Breadcrumb Area -->

    <Breadcrumb 
    mainTitle="Price" 
    breadContent="We provide products Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse provident explicabo atque numquam enim debitis earum minus, perferendis sed ipsum." 
    subTitle="Price"
    />

<!--Pricing Section -->

<div class="pricing-section section-padding pt-50">
    <div class="container">
        <div class="row no-gutters">
            <div class="col-lg-4 col-md-6">
                <div class="single-price-item wow fadeInLeft" data-wow-delay=".3s">
                    <h5>Standard</h5>
                    <div class="price-box">
                        <p>$<b>12</b>/Sq Feet</p>
                        <h6>Perfect for Residential</h6>
                    </div>
                    <div class="price-list">
                        <ul>
                            <li><i class="las la-check-circle"></i>Concept Project</li>  
                            <li><i class="las la-check-circle"></i>Site Planning</li>
                            <li><i class="las la-check-circle"></i>Material Work</li>
                            <li><i class="las la-check-circle"></i>Hire Worker</li>
                        </ul>
                    </div>
                    <div class="main-btn small-btn">Choose Plan</div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-price-item active wow fadeInUp" data-wow-delay=".4s">
                    <h5>Advance</h5>
                    <div class="price-box">
                        <p>$<b>150</b>/Sq Feet</p>
                        <h6>Perfect for Commercial</h6>
                    </div>
                    <div class="price-list">
                        <ul>
                            <li><i class="las la-check-circle"></i>Concept Project</li>  
                            <li><i class="las la-check-circle"></i>Site Planning</li>
                            <li><i class="las la-check-circle"></i>Material Work</li>
                            <li><i class="las la-check-circle"></i>Hire Worker</li>
                        </ul>
                    </div>
                    <div class="main-btn small-btn">Choose Plan</div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-price-item wow fadeInRight" data-wow-delay=".5s">
                    <h5>Premium</h5>
                    <div class="price-box">
                        <p>$<b>300</b>/Sq Feet</p>
                        <h6>Perfect for Industrial</h6>
                    </div>
                    <div class="price-list">
                        <ul>
                            <li><i class="las la-check-circle"></i>Concept Project</li>  
                            <li><i class="las la-check-circle"></i>Site Planning</li>
                            <li><i class="las la-check-circle"></i>Material Work</li>
                            <li><i class="las la-check-circle"></i>Hire Worker</li>
                        </ul>
                    </div>
                    <div class="main-btn small-btn">Choose Plan</div>
                </div>
            </div>

        </div>
    </div>
</div>

</template>

<script>

import HeaderTopbar from '@/components/HeaderTopbar.vue'
import NavbarHome1 from '@/components/NavbarHome1.vue'
import Search from '@/components/Search.vue'
import TestimonialOne from '@/components/TestimonialOne.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'

export default {
    name: 'Price',
    components : {
        HeaderTopbar, 
        NavbarHome1,
        Search, 
        TestimonialOne, 
        Breadcrumb,
    },
	data(){
        return {            	
			
			
        }
    },

	async mounted(){    

    //scrolling to top of the window
    window.scrollTo(0, 0); 
		


	}
   
}
</script>