<template>

    <!-- Header Top  -->

    <HeaderTopbar/>

    <!-- Header Area -->

    <NavbarHome1/>

    <!-- Search Dropdown Area -->

    <Search/>

<!-- Breadcrumb Area -->

<Breadcrumb 
    mainTitle="Blog Details" 
    breadContent="We provide products Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse provident explicabo atque numquam enim debitis earum minus, perferendis sed ipsum." 
    subTitle="Blog Details"
/>

<!-- Blog Details  -->

<div id="blog-page" class="blog-section section-padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <div class="single-blog-wrap">
                        <img :src="images.blog_image" :alt="currentBlog.title">
                        <div class="blog-meta">
                            <span><i class="las la-user"></i>{{currentBlog.author}}</span>
                            <span><i class="las la-calendar"></i>{{currentBlog.date}}</span>
                            <span><i class="las la-comments"></i>{{comments.length}} Comments</span>
                        </div>
                        <h3>{{currentBlog.title}}</h3>
                        <p>{{currentBlog.blog_content}}</p>
                        <h5> {{currentBlog.sub_title_1}}</h5>
                        <p>{{currentBlog.sub_title_1_content}}</p>
                        <div class="row">
                            <div class="col-lg-6 mb-30">
                                <img class="blog-inside" :src="images.image_1" alt="">
                            </div>
                            <div class="col-lg-6">
                                <img class="blog-inside" :src="images.image_2" alt="">
                            </div>
                        </div>

                        <h5> {{currentBlog.sub_title_2}}</h5>
                        <p>{{currentBlog.sub_title_2_content}}</p>
                        <hr>
                        <!-- <div class="author-profile">
                            <img src="/assets/img/blog/admin.jpg" alt="">
                            <div class="author-info">
                                <h6>Harvert Milan</h6>
                                <p>While publishing our article in a journal, to claim it as our article, where should
                                    be our name in the authors list, the first name</p>
                                <div class="social-icon">
                                    <ul>
                                        <li><a href="#" class=""><i class="lab la-facebook-f"></i></a></li>
                                        <li><a href="#" class=""><i class="lab la-twitter"></i></a></li>
                                        <li><a href="#" class=""><i class="lab la-pinterest-p"></i></a></li>
                                        <li><a href="#" class=""><i class="lab la-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div> -->
                        <hr>

                        <div class="comments-section">
                            <h5>Comments ({{comments.length}})</h5>

                            <div class="single-comments-section" v-for="item in comments" :key="item.id">
                                <img :src="item.author_image" :alt="item.author_name">
                                <p><b>{{item.author_name}}</b><span>{{item.date}}</span></p>
                                <p>{{item.comment}}</p>
                                <p><a href="#" class="reply-btn">Reply</a></p>
                            </div>

                            <!-- <div class="single-comments-section">
                                <img src="/assets/img/blog/client-2.jpg" alt="">
                                <p><b>Ryans Joseph</b><span>November 29, 2022</span></p>
                                <p>Though author order shouldn't matter, industry custom and practical limitations say
                                    otherwise.</p>
                                <p><a href="#" class="reply-btn">Reply</a></p>
                            </div> -->
                        </div>
                    </div>
                    <div class="comments-form">
                        <h3>Leave A Reply</h3>
                        <p>Your email address will not be published. Required fiels are marked</p>
                        <div class="row">
                            <div class="col-lg-12">
                                <textarea name="message" id="message" cols="30" rows="10" placeholder="Write Your Comment"></textarea>
                            </div>
                            <div class="col-lg-6">
                                <input type="text" placeholder="Your Name">
                            </div>
                            <div class="col-lg-6">
                                <input type="email" placeholder="Email here">
                            </div>
                            <div class="col-lg-12">
                                <button type="submit">Post Comment</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="blog-search">
                        <form action="blog.html">
                            <input type="search" placeholder="Search here">
                            <button type="submit"><i class="las la-search"></i></button>
                        </form>
                    </div>
                    <div class="blog-category">
                        <h5>Categories</h5>
                        <a class="active" href="#">Solar Energy (8)</a>
                        <a href="#">Wind Turbine (6)</a>
                        <a href="#">Battery Backup (4)</a>
                        <a href="#">Electricy (5)</a>
                        <a href="#">Ecology (2)</a>
                    </div>
                    <div class="recent-post">
                        <h5>Recent Post</h5>                        
                        <div class="single-recent-post">
                            <div class="post-thumb">
                                <img src="/assets/img/blog/recent_post_thumb.jpg" alt="">
                            </div>
                            <div class="post-content">
                                <h6>Top 10 Solar Tech Gadgets for Your Home</h6>
                            </div>                            
                        </div>
                        <div class="single-recent-post">
                            <div class="post-thumb">
                                <img src="/assets/img/blog/recent_post_thumb-2.jpg" alt="">
                            </div>
                            <div class="post-content">
                                <h6>The Process of Solar Panel Manufacturing</h6>
                            </div>                            
                        </div>
                        <div class="single-recent-post">
                            <div class="post-thumb">
                                <img src="/assets/img/blog/recent_post_thumb-3.jpg" alt="">
                            </div>
                            <div class="post-content">
                                <h6>Solar Energy Research and Development</h6>
                            </div>                            
                        </div>
                    </div>
                    <div class="archive">
                        <h5>Archive</h5>
                        <a class="active" href="#">Solar Energy (25)</a>
                        <a href="#">Ecology Studies (18)</a>
                        <a href="#">Wind Energy (32)</a>
                        <a href="#"> Green House (13)</a>
                        <a href="#"> Battery Charging (04)</a>
                    </div>
                    <div class="popular-tag">
                        <h5>Popular Tags</h5>
                        <span class="active"><a href="#">Solar Panel</a></span>
                        <span><a href="#">Renewable</a></span>
                        <span><a href="#">Energy</a></span>
                        <span><a href="#">Wind Turbine</a></span>
                        <span><a href="#">Electriciy</a></span>
                        <span><a href="#">Ecology</a></span>
                    </div>
                    <div class="helpline-section">
                        <div class="overlay-2"></div>
                        <div class="helpline-content text-center">
                            <h4>Need <br > Consultancy Help</h4>
                            <p>Gatherin galso sprit moving shall flow</p>
                            <button type="submit">Contact Us</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>

<script>

import axios from 'axios'

import HeaderTopbar from '@/components/HeaderTopbar.vue'
import NavbarHome1 from '@/components/NavbarHome1.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import Search from '@/components/Search.vue'


export default {
    name: 'BlogDetails',
    components : {HeaderTopbar, NavbarHome1, Breadcrumb, Search},
	data(){
        return {
            blogs : [], 
            archives : [],
            categories : [],
            tags : [],
            currentBlog : [],
            images : [],
            comments : [],			
        }
    },

	async mounted(){    
		
		//scrolling to top of the window
        window.scrollTo(0, 0);  

		let fetcheBlogsData = await axios.get("/assets/data/blogs.json");
        this.blogs = fetcheBlogsData.data.blogs;

        this.currentBlog = this.blogs.find((item) => item.id == this.$route.params.id);
        this.images = this.currentBlog.images;
        this.comments = this.currentBlog.comments;

        let fetchedCategoryData = await axios.get("/assets/data/categories.json");
        this.categories = fetchedCategoryData.data.categories;

        let fetchedTagData = await axios.get("/assets/data/tags.json");
        this.tags = fetchedTagData.data.tags;

        let fetchedArchiveData = await axios.get("/assets/data/archives.json");
        this.archives = fetchedArchiveData.data.archives;

	}
   
}
</script>