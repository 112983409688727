<template>

    <!-- Header Top  -->

    <HeaderTopbar/>

    <!-- Header Area -->

    <NavbarHome1/>

    <!-- Search Dropdown Area -->

    <Search/>

    <!-- Breadcrumb Area -->

    <Breadcrumb 
    mainTitle="FAQ" 
    breadContent="We provide products Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse provident explicabo atque numquam enim debitis earum minus, perferendis sed ipsum." 
    subTitle="FAQ"
    />


<!--FAQ Section -->

<div class="faq-section section-padding pb-50">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 wow fadeInDown" data-wow-delay=".3s">
            <div class="accordion faqs" id="accordionFaq">
                <div class="card" v-for="faq in faqs" :key="faq.id">                            
                    <div class="card-header" id="heading1">
                        <h5 class="mb-0 subtitle">
                            <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse-'+faq.id" aria-expanded="false" :aria-controls="'collapse-'+faq.id">
                                {{faq.question}}
                            </button>
                        </h5>
                    </div>

                    <div :id="'collapse-'+faq.id" class="collapse" :aria-labelledby="'heading-'+faq.id" data-parent="#accordionFaq">
                        <div class="card-body">
                            <div class="content">
                                <p>{{faq.answer}}</p>
                            </div>
                        </div>
                    </div>
                </div>                        
            </div>
        </div>
        <div class="col-xl-4 col-lg-4">
          <div class="question-section">
            <h6>Have any Question?</h6>
            <form action="sendemail.php">
              <input type="text" name="name" id="name" required="" placeholder="Full Name">
              <input type="email" name="email" id="email" required="" placeholder="Your E-mail">
              <textarea name="message" id="message" cols="30" rows="10" required="" placeholder="How can help you?"></textarea>
              <button type="submit" class="main-btn white">Submit Request</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

<!-- CTA Area  -->

<div class="cta-area">
        <div class="container">
            <div class="row cta-content">
                <div class="col-lg-7 col-md-7 col-12 wow fadeInUp" data-wow-delay=".2s">
                    <h2>Let's Started Your Project </h2>
                    <p>Make an appointment get quote Lorem, ipsum dolor.</p>
                </div>
                <div class="col-lg-5 col-md-5 col-12 text-end wow fadeInUp" data-wow-delay=".3s">
                    <router-link :to="{name:'Price'}" class="main-btn">Get a Quote</router-link>
                </div>
            </div>
        </div>
 </div>


</template>

<script>

import axios from 'axios'

import HeaderTopbar from '@/components/HeaderTopbar.vue'
import NavbarHome1 from '@/components/NavbarHome1.vue'
import Search from '@/components/Search.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'

export default {
    name: 'FAQ',
    components : {HeaderTopbar, NavbarHome1, Search, Breadcrumb},
	  data(){
        return {            
            faqs: [],
			
        }
    },

	async mounted(){    
		
    //scrolling to top of the window
    window.scrollTo(0, 0);  

    let fetcheFaqData = await axios.get("/assets/data/faq.json");
    this.faqs = fetcheFaqData.data.faqs;

	}
   
}
</script>