<template>

    <!-- Header Top  -->

    <HeaderTopbar/>

    <!-- Header Area -->

    <NavbarHome1/>

    <!-- Search Dropdown Area -->

    <div class="search-popup">
        <span class="search-back-drop"></span>

        <div class="search-inner">
            <div class="container">
                <div class="upper-text">
                    <div class="text">Search for anything.</div>
                    <button class="close-search"><span class="la la-times"></span></button>
                </div>

                <form method="post" action="index.html">
                    <div class="form-group">
                        <input type="search" name="search-field" value="" placeholder="Search..." required="">
                        <button type="submit"><i class="la la-search"></i></button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <!-- Breadcrumb Area -->

    <Breadcrumb 
    mainTitle="Hydropower Plant" 
    breadContent="We provide products Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse provident explicabo atque numquam enim debitis earum minus, perferendis sed ipsum." 
    subTitle="Hydropower Plant"
    />



    <!--Service Details Start-->
     <section class="service-details pb-50">
        <div class="container">
            <div class="row gx-5">
                <div class="col-xl-4 col-lg-5">
                    <div class="service-details_sidebar">
                        <div class="service-details_sidebar-service">
                            <ul class="service-details_sidebar-service-list list-unstyled">                                
                                <li class="d-block"><router-link :to="{name:'RenewableEnergy'}">Renewable Energy <span> <i class="las la-long-arrow-alt-right"></i></span></router-link></li>
                                <li class="d-block"><router-link :to="{name:'SolarEnergy'}">Solar Energy <span> <i class="las la-long-arrow-alt-right"></i></span></router-link></li>
                                <li class="d-block"><router-link :to="{name:'WindTurbine'}">Wind Turbine <span> <i class="las la-long-arrow-alt-right"></i></span></router-link></li>
                                <li class="d-block current"><router-link :to="{name:'HydropowerPlant'}">Hydropower Plant <span> <i class="las la-long-arrow-alt-right"></i></span></router-link></li>
                                <li class="d-block"><router-link :to="{name:'SolarDevelopment'}">Solar Development <span> <i class="las la-long-arrow-alt-right"></i></span></router-link></li>
                                <li class="d-block "><router-link :to="{name:'BatteryBackup'}">Battery Backup <span> <i class="las la-long-arrow-alt-right"></i></span></router-link></li>
                            </ul>                            
                        </div>
                        <div class="need_help_img">
                            <img src="assets/img/need_help.jpg" alt="">
                        </div> 
                        <div class="service-details_need-help">
                                                      
                            <h2 class="service-details_need-help-title">Let's Help You for Advice</h2>
                            <div class="service-details_need-help-icon">
                                <i class="las la-phone"></i>
                            </div>
                            <div class="service-details_need-help-contact">
                                <p>Call Anytime</p>
                                <a href="tel:13077760608">+ 1 (307) 776-0608</a>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-xl-8 col-lg-7">
                    <div class="service-details_right" v-for="single_service in single_services" :key="single_service.id"> 
                        <div class="service-details_img">
                            <img :src="single_service.detail_image" alt="detail_image">                                                        
                        </div>                       
                        <div class="service-details_content">
                            <h3 class="service-details_title">{{single_service.service_headlineOne }}</h3>
                            <p class="service-details_text-1">{{single_service.service_desc_one}}</p>
                            <br>
                            <p class="service-details_text-2">{{single_service.service_desc_two}}</p>                            
                        </div>

                        <div class="service_details_slider mt-30">
                            <swiper
                            :modules="modules"
                            :slides-per-view="1"
                            :space-between="0"
                            navigation
                            :pagination="{ clickable: true }"
                            :autoplay="{ delay: 4000 }"
                            >
                            <swiper-slide>
                                <div class="slider-image">
                                    <img :src="single_service.slider_img_one" alt="">                                                                
                                </div>
                            </swiper-slide>
                            <swiper-slide>
                                <div class="slider-image">
                                    <img :src="single_service.slider_img_two" alt="">                                                                
                                </div>
                            </swiper-slide>
                            </swiper>
                        </div>

                        <div class="service-details_content">  
                            <h3 class="service-details_title">{{single_service.service_headlineTwo }}</h3>                                                      
                            <p class="service-details_text-2">{{single_service.service_desc_three}}</p>
                            <br>
                        </div> 
                        <div class="service-details_img">
                            <img :src="single_service.video_image" alt="">                                                                
                            <div class="pop-up-video">
                                <a href="https://www.youtube.com/watch?v=yFwGpiCs3ss" class="video-play-btn mfp-iframe">
                                    <i class="las la-play"></i> <span></span>
                                </a>
                            </div>                           
                        </div>                       
                        <div class="service-details_content">  
                            <h3 class="service-details_title">{{single_service.service_headlineThree}}</h3>                                                      
                            <p class="service-details_text-2">{{single_service.service_desc_four}} </p>
                            <br>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>

import axios from 'axios'

import HeaderTopbar from '@/components/HeaderTopbar.vue'
import NavbarHome1 from '@/components/NavbarHome1.vue'
import TestimonialOne from '@/components/TestimonialOne.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination, A11y, Autoplay } from "swiper";


// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";


export default {
    name: 'Services',
    components : {HeaderTopbar, NavbarHome1, Swiper, SwiperSlide, TestimonialOne, Breadcrumb},
	data(){
        return {
            modules: [Navigation, Pagination, A11y, Autoplay],
            swiperOptions: {
                breakpoints: {
                354: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                575: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                767: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                },

                990: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                1200: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                },
                },
            },	
			single_services : [], 					
			
        }
    },

	async mounted(){  
        
        //scrolling to top of the window
        window.scrollTo(0, 0);          
		
        let fetcheSingleServicesdData = await axios.get("/assets/data/single_services.json");
        this.single_services = fetcheSingleServicesdData.data.single_services;
	
	}
   
}
</script>