<template>

    <!-- Header Top  -->

    <HeaderTopbar/>

    <!-- Header Area -->

    <NavbarHome1/>

    <!-- Search Dropdown Area -->

    <Search/>

    <Breadcrumb 
        mainTitle="Services" 
        breadContent="We provide products Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse provident explicabo atque numquam enim debitis earum minus, perferendis sed ipsum." 
        subTitle="Services"
    />

    <!-- Service Section  -->

    <div class="service-area gray-bg section-padding pt-50 pb-50">
        <div class="container">
            <div class="service-item-wrap mt-40">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3 wow fadeInLeft mb-50" data-wow-delay=".2s" v-for="service in services" :key="service.id">
						<div class="service-single" :class="'service-'+service.id">
							<div class="service-icon">								
								<img :src="service.icon">
							</div>
							<div class="service-content">
								<h4>{{service.title}}</h4>
								<p>{{service.short_description}}</p>
								<router-link :to="{name:'SolarEnergy'}">Learn More <i class="las la-long-arrow-alt-right"></i></router-link>
							</div>
						</div>
					</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Our Story Section  -->
    <div class="our-story section-padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 wow fadeInLeft" data-wow-delay=".2s">
                    <div class="our-story-inner">
                        <div class="section-title">
                            <h6>Core Feature</h6>
                            <h2>Save the Planet by <br> using Green Energy</h2>
                        </div>
                        <p>Soltrin provide Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam qui incidunt a sapiente quibusdam commodi!</p>
                        <div class="feature-wrap">
					
                    <div class="feature-inner" v-for="feature in features" :key="feature.id">
                    <div class="feature-icon">
                        <i class="las la-check-circle"></i>
                    </div>
                    <div class="feature-content" :class="'feature-'+feature.id">
                        <h6>{{feature.title}}</h6>
                        <p>{{feature.short_description}}</p>
                    </div>
                
                </div>					
            </div>
                    </div>
                </div>
                <div class="col-lg-3 wow fadeInUp" data-wow-delay=".2s">
                    <div class="story-img">
                        <img src="assets/img/story_img.jpg" alt="">
                        <br><br>
                        <img src="assets/img/story_img_2.jpg" alt="">
                    </div>
                </div>
                <div class="col-lg-3 wow fadeInRight" data-wow-delay=".2s">
                    <div class="counter-wrap">
                        <div class="counter-number">
                            <p class="counting">16</p>
                            <h6>Presence</h6>
                            <p>Countries accross five continents</p>
                        </div>
                        <div class="counter-number">
                            <p class="counting">43</p>
                            <h6>Gr. Total</h6>
                            <p>Installed Solar Panels successfully</p>
                        </div>
                        <div class="counter-number">
                            <p class="counting">102</p>
                            <h6>Projects</h6>
                            <p>Solar Solutions & Power Energy Operative</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Testimonial Section  -->

<TestimonialOne/>

    <!--Pricing Section -->

    <div class="pricing-section section-padding">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12">
                    <div class="section-title text-center">
                        <h6>Pricing</h6>
                        <h2>Choose Your Affordable <br>Package</h2>
                    </div>
                </div>
            </div>
            <div class="row no-gutters">
                <div class="col-lg-4 col-md-6">
                    <div class="single-price-item wow fadeInLeft" data-wow-delay=".3s">
                        <h5>Standard</h5>
                        <div class="price-box">
                            <p>$<b>12</b>/Sq Feet</p>
                            <h6>Perfect for Residential</h6>
                        </div>
                        <div class="price-list">
                            <ul>
                                <li><i class="las la-check-circle"></i>Concept Project</li>
                                <li><i class="las la-check-circle"></i>Site Planning</li>
                                <li><i class="las la-check-circle"></i>Material Work</li>
                                <li><i class="las la-check-circle"></i>Hire Worker</li>
                            </ul>
                        </div>
                        <div class="main-btn small-btn">Choose Plan</div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-price-item active wow fadeInUp" data-wow-delay=".4s">
                        <h5>Advance</h5>
                        <div class="price-box">
                            <p>$<b>150</b>/Sq Feet</p>
                            <h6>Perfect for Commercial</h6>
                        </div>
                        <div class="price-list">
                            <ul>
                                <li><i class="las la-check-circle"></i>Concept Project</li>
                                <li><i class="las la-check-circle"></i>Site Planning</li>
                                <li><i class="las la-check-circle"></i>Material Work</li>
                                <li><i class="las la-check-circle"></i>Hire Worker</li>
                            </ul>
                        </div>
                        <div class="main-btn small-btn">Choose Plan</div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="single-price-item wow fadeInRight" data-wow-delay=".5s">
                        <h5>Premium</h5>
                        <div class="price-box">
                            <p>$<b>300</b>/Sq Feet</p>
                            <h6>Perfect for Industrial</h6>
                        </div>
                        <div class="price-list">
                            <ul>
                                <li><i class="las la-check-circle"></i>Concept Project</li>
                                <li><i class="las la-check-circle"></i>Site Planning</li>
                                <li><i class="las la-check-circle"></i>Material Work</li>
                                <li><i class="las la-check-circle"></i>Hire Worker</li>
                            </ul>
                        </div>
                        <div class="main-btn small-btn">Choose Plan</div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <!-- CTA Area  -->

    <div class="cta-area">
        <div class="container">
            <div class="row cta-content">
                <div class="col-lg-7 col-md-7 col-12 wow fadeInUp" data-wow-delay=".2s">
                    <h2>Let's Started Your Project </h2>
                    <p>Make an appointment get quote Lorem, ipsum dolor.</p>
                </div>
                <div class="col-lg-5 col-md-5 col-12 text-center wow fadeInUp" data-wow-delay=".3s">
                    <router-link :to="{name:'Price'}" class="main-btn">Get a Quote</router-link>
                </div>
            </div>
        </div>
    </div>

    <!-- Client Section  -->

    <div class="client-area pt-40 pb-40">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="client-wrap owl-carousel">
                        <div class="single-client">
                            <a href="#"><img src="assets/img/client/1.png" alt=""></a>
                        </div>
                        <div class="single-client">
                            <a href="#"><img src="assets/img/client/2.png" alt=""></a>
                        </div>
                        <div class="single-client">
                            <a href="#"><img src="assets/img/client/3.png" alt=""></a>
                        </div>
                        <div class="single-client">
                            <a href="#"><img src="assets/img/client/4.png" alt=""></a>
                        </div>
                        <div class="single-client">
                            <a href="#"><img src="assets/img/client/5.png" alt=""></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import axios from 'axios'

import HeaderTopbar from '@/components/HeaderTopbar.vue'
import NavbarHome1 from '@/components/NavbarHome1.vue'
import Search from '@/components/Search.vue'
import TestimonialOne from '@/components/TestimonialOne.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'


export default {
    name: 'Services',
    components : {
        HeaderTopbar, 
        NavbarHome1, 
        Search,
        TestimonialOne,
        Breadcrumb
    },
	data(){
        return {            	
			services : [], 		
			features : [], 								
        }
    },

	async mounted(){ 
        
        //scrolling to top of the window
        window.scrollTo(0, 0); 
		
		let fetcheServicesdData = await axios.get("/assets/data/services.json");
        this.services = fetcheServicesdData.data.services;

		let fetcheFeatureData = await axios.get("/assets/data/features.json");
        this.features = fetcheFeatureData.data.features;

		let fetcheProjectData = await axios.get("/assets/data/projects.json");
        this.projects = fetcheProjectData.data.projects;

		let fetcheProcessData = await axios.get("/assets/data/processes.json");
        this.processes = fetcheProcessData.data.processes;

		let fetcheBlogsData = await axios.get("/assets/data/blogs.json");
        this.blogs = fetcheBlogsData.data.blogs;

	}
   
}
</script>