<template>
    <!-- Footer Area -->

    <footer class="footer-area">
        <div class="container">
            <div class="footer-up">
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="logo">
                            <img src="/assets/img/logo-white.png" alt="soltrin-logo">
                        </div>
                        <div class="contact-info">
                            <p><b>Location:</b> {{contactInfo.location}}</p>
                            <p><b>Phone:</b> {{contactInfo.phone}}</p>
                            <p><b>E-mail:</b>{{contactInfo.email}}</p>
                            <p><b>Opening Hour:</b> {{contactInfo.opening_hour}}</p>
                        </div>

                    </div>
                    <div class="col-lg-5 col-md-6 com-sm-12">

                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-12">
                                <h6>Company</h6>
                                <ul>
                                    <li>
                                        <router-link :to="{name:'About'}">About Us</router-link>
                                        <router-link :to="{name:'Team'}">Meet Our Team</router-link>
                                        <router-link :to="{name:'Blog'}">News & Media</router-link>
                                        <router-link :to="{name:'Project'}">Our Project</router-link>
                                        <router-link :to="{name:'Contact'}">Contact</router-link>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-lg-6 col-md-6 col-12">
                                <h6>Our Offer</h6>
                                <ul>
                                    <li>
                                        <router-link :to="{name:'SolarEnergy'}">Solar Energy</router-link>
                                        <router-link :to="{name:'SolarDevelopment'}">Install Solar Panel</router-link>
                                        <router-link :to="{name:'WindTurbine'}">Wind Turbine</router-link>
                                        <router-link :to="{name:'BatteryBackup'}">Battery Charging</router-link>
                                        <router-link :to="{name:'SolarEnergy'}">Solar Energy</router-link>

                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6">
                        <div class="subscribe-form">
                            <h6>Newsletter</h6>
                            <form action="index.html">
                                <input type="email" placeholder="Your email">
                                <button type="submit"><i class="las la-envelope"></i></button>
                            </form>
                            <p>Stay tuned for our latest news</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>

    <!-- Footer Bottom Area  -->

    <div class="footer-bottom">
        <div class="container">
            <div class="row justify-content-center align-items-center">
                <div class="col-lg-3 col-12">
                    <div class="copyright-area">
                        <p class="copyright-line">© 2023 Soltrin. All rights reserved.</p>
                    </div>
                </div>
                <div class="col-lg-6 col-12">
                    <p class="privacy"><a href="#">Terms &amp; Conditions</a> <a href="#">Privacy Policy</a> <a href="#">Solar Panel Tips</a> </p>
                </div>
                <div class="col-lg-3 col-12 text-end">
                    <div class="social-area">
                        <a href=""><i class="lab la-facebook-f"></i></a>
                        <a href=""><i class="lab la-youtube"></i></a>
                        <a href=""><i class="lab la-twitter"></i></a>
                        <a href=""><i class="lab la-instagram"></i></a>
                        <a href=""><i class="lab la-linkedin"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data(){
        return {
            contactInfo : [],         
        }
    },

    async mounted(){
        let fetchedData = await axios.get("/assets/data/contactInfo.json");
        this.contactInfo = fetchedData.data.contactInfo;

    },

}
</script>